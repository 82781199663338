<template>
  <HomeLayout>
    <b-container
      class="d-flex flex-column align-items-start justify-content-start px-0"
    >
      <!-- user insights row -->
      <b-row
        class="d-flex flex-row align-items-stretch justify-content-center w-100"
        no-gutters
      >
        <b-card
          no-body
          class="position-relative shadow-sm border-1 filter-cards-row w-100"
        >
          <b-row
            class="d-flex flex-row align-items-center justify-content-start px-3 w-100"
            no-gutters
          >
            <!-- Overdue column -->
            <b-col
              class="d-flex flex-column align-items-start justify-content-center px-0 py-2 py-lg-0"
              cols="6"
            >
              <h6 class="text-left text-truncate text-medium font-weight-bold">
                Filters
              </h6>
            </b-col>
            <!-- Upcoming payout column -->
            <b-col
              class="d-flex flex-column align-items-end justify-content-center px-0 py-2 py-lg-0"
              cols="6"
            >
              <b-dropdown
                variant="white"
                toggle-class="d-flex flex-row align-items-center justify-content-center mx-0 my-2 my-lg-0 text-main-green border border-main-green text-main-greene rounded-lg rounded-pill export-btn"
                text="Export"
              >
                <b-dropdown-item @click="exportBalanceSheetFn"
                  >CSV</b-dropdown-item
                >
                <b-dropdown-item @click="exportPDFFinancesheetFn"
                  >PDF</b-dropdown-item
                >
              </b-dropdown>
            </b-col>
          </b-row>
          <!-- date filters row  -->
          <b-row
            class="d-flex flex-row align-items-start align-items-md-center justify-content-between mt-2 mt-sm-4 px-3 date-filter-row"
            no-gutters
          >
            <b-col
              cols="12"
              sm="2"
              md="1"
              class="mb-2 mb-md-0 align-self-center"
            >
              <span class="text-left text-dark font-primary font-weight-normal"
                >Date Range
              </span>
            </b-col>

            <b-col
              cols="12"
              sm="5"
              md="2"
              class="mb-2 mb-md-0 align-self-center mt-3"
            >
              <FormSelect
                groupId="dateRange-group"
                id="dateRange-group-input"
                class="text-prime-gray rounded-2 report-select align-self-center"
                v-model="date_range"
                :whiteBG="false"
                :options="dateRangeOptions"
                form="login-form"
              ></FormSelect>
            </b-col>

            <b-col
              cols="12"
              sm="9"
              lg="5"
              class="w-100 d-flex flex-column flex-md-row"
            >
              <b-col cols="12" md="6" lg="7" class="mb-3 mb-md-0 px-0">
                <b-input-group>
                  <b-form-input
                    id="example-input"
                    :value="formatDate(startDate)"
                    type="text"
                    class="border-main-green date-picker"
                    placeholder="From"
                    autocomplete="off"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      class="date-picker-btn font-primary datepicker align-items-right"
                      v-model="startDate"
                      no-flip
                      button-only
                      button-variant="white"
                      locale="en-US"
                      right
                      :state="startDateError"
                      nav-button-variant="main-green"
                      today-variant="main-green"
                      menu-class=" rounded-lg custom-calendar-dropdown text-prime-gray"
                      aria-controls="start-date-input"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <b-col cols="12" md="6" lg="7" class="pl-0">
                <b-input-group>
                  <b-form-input
                    id="example-input"
                    :value="formatDate(endDate)"
                    class="border-main-green date-picker"
                    type="text"
                    placeholder="To"
                    autocomplete="off"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      class="date-picker-btn font-primary datepicker align-items-right"
                      v-model="endDate"
                      :min="minEndDate"
                      no-flip
                      button-only
                      button-variant="white"
                      locale="en-US"
                      right
                      :state="endDateError"
                      nav-button-variant="main-green"
                      today-variant="main-green"
                      menu-class=" rounded-lg custom-calendar-dropdown text-prime-gray"
                      aria-controls="start-date-input"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-col>
            <b-col cols="0" lg="2"> </b-col>
          </b-row>
          <!-- full row input (No :) -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-start w-100 px-3"
            no-gutters
          >
            <b-col
              class="d-flex flex-row align-items-center justify-content-end py-1"
            >
              <b-link
                class="d-flex flex-column align-items-end justify-content-center bg-white border-0 text-left font-primary text-main-green font-weight-bold load-more-btn"
                pill
                @click="clearFilterFn"
                v-if="computedFilterValidations"
              >
                <span class="d-inline mx-1 py-1 mt-3">Clear all</span>
              </b-link>
            </b-col>
            <b-col
              class="d-flex flex-row align-items-end justify-content-end mb-3 mb-md-4 py-1"
            >
              <b-button
                variant="main-green"
                class="d-flex flex-column align-items-end justify-content-end border-0 action-btn"
                pill
                @click="updateReport"
              >
                <span class="d-inline mx-1 py-1">Update Report</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-row>
      <!-- table container row -->
      <b-row
        class="d-flex flex-row align-items-stretch justify-content-center w-100 px-1 py-0 py-lg-1 mt-0 mt-lg-3"
        no-gutters
      >
        <b-card no-body class="shadow-sm border-0 px-3 py-3 w-100">
          <!-- header row  -->
          <b-row
            class="d-flex flex-row align-items-center justify-content-start py-3 px-3 w-100"
            no-gutters
          >
            <!-- Accounts column -->
            <b-table
              striped
              hover
              :items="items"
              :fields="fields"
              ref="selectableTable"
              table
              head-variant="light"
              selectable
              show-empty
              select-mode="multi"
              class="table-element"
              thead-class="table-header"
            >
              <template #cell(accounts)="data">
                <div>
                  <p
                    class="p-0 m-0 font-weight-bold"
                    :class="data.index === 5 ? 'hiddenText' : ''"
                  >
                    {{ subtitle[data.index] }}
                  </p>
                  <p
                    class="mt-2 line-septate"
                    v-for="(item, index) in data.item.accounts"
                    :key="index"
                  >
                    {{ item }}
                  </p>
                </div>
              </template>
              <template v-slot:[`cell(${di})`]="data" v-for="di in dataList">
                <p
                  class="mb-3 line-septate"
                  v-for="(item, index) in data.item[di]"
                  :key="index"
                  :class="index === 0 ? 'mr-top' : ''"
                >
                  {{ item }}
                </p>
              </template>
            </b-table>
          </b-row>
        </b-card>
      </b-row>
    </b-container>
  </HomeLayout>
</template>
<script>
//utils
import { formatNumbers } from "@/util/formatNumbers.js";

// @ is an alias to /src
import { baseURL } from "@/services/config";
import HomeLayout from "@/layout/HomeLayout";
import FormSelect from "@/components/Form/FormSelect";

// services
import {
  // GetTrialBalance,
  GetFinancialReport,
  GetFinancialReportFilters,
  ExportFinancialReport,
  ExportFinancialReportpdf,
} from "@/services/reports.service";
import { GetCurrentWorkspace } from "@/services/settingsbusiness.service";

export default {
  name: "FinancialReport",
  components: {
    HomeLayout,
    FormSelect,
  },
  data() {
    return {
      currantYear: null,
      lastYear: null,
      date_range: undefined,
      subtitle: [
        "Executive Summary",
        "Cash Summary",
        "Profit & Loss",
        "Balance Sheet",
        "Aged Receivables Summary",
        "fdsf",
      ],
      slotName: "cell(year2023)",
      dataList: [],
      fields: [
        {
          key: "accounts",
          // variant: "primary",
        },
      ],
      items: [
        {
          accounts: [
            "Cash And Bank",
            "Depreciation and Amortization",
            "Expected Payments from Customers",
            "Inventory",
            "Money in Transit",
            "Other Long-Term Asset",
            "Other Short-Term Asset",
            "Property / Plant / Equipment",
            "Vendor Prepayments and Vendor Credits",
            "totalAssetsValue",
          ],
          // namer: "Hello World",
        },
        {
          accounts: [
            "Credit Card",
            "Customer Prepayments and Customer Credits",
            "Due For Payroll",
            "Due to You and Other Business Owners",
            "Expected Payments to Vendors",
            "Loan and Line of Credit",
            "Other Long-Term Liability",
            "Other Short-Term Liability",
            "Sales Taxes",
            "totalLiabilitiesValue",
          ],
          // namer: "Hello World",
        },
        {
          accounts: [
            "Business Owner Contribution and Drawing",
            "Retained Earnings Profit",
            "totalEquityValues",
          ],
          // namer: "Hello World",
        },
        {
          accounts: [
            "Discount",
            "Gain On Foreign Exchange",
            "Income",
            "Other Income",
            "Uncategorized Income",
            "totalIncomeValue",
          ],
          // namer: "Hello World",
        },
        {
          accounts: [
            "Cost of Goods Sold",
            "Loss On Foreign Exchange",
            "Operating Expense",
            "Payment Processing Fee",
            "Payroll Expense",
            "Uncategorized Expense",
            "totalExpensesValue",
          ],
          // namer: "Hello World",
        },
        {
          accounts: ["totalForAllAccounts"],
          // namer: "Hello World",
        },
      ],
      dateRangeOptions: [
        "This financial year",
        "Last financial year",
        "Last year Q1",
        "Last year Q2",
        "Last year Q3",
        "Last year Q4",
      ],
      currency: localStorage.getItem("CURRENCY"),
      isLoading: false,
      startDate: null,
      endDate: null,
      startDateError: null,
      endDateError: null,
      reportType: null,
      isFiltersApplied: false,
      reportTypeOptions: [
        {
          text: "Accrual (Paid & Unpaid)",
          value: "accrual",
        },
        {
          text: "Cash Basis (Paid)",
          value: "cash",
        },
      ],
      trialBalanceData: {},
    };
  },
  async mounted() {
    window.scrollTo(0, 0);
    await this.loadCurrentWorkspace();
    await this.initFn();
  },
  watch: {
    startDate(val) {
      // reset the start date when end date changes
      this.endDate = "";
      if(val){
      this.date_range=null
     }
    },
    endDate(val){
     if(val){
      this.date_range=null
     }
    }
  },
  computed: {
    minEndDate() {
      return this.startDate;
    },
    computedFilterValidations() {
      if (this.reportType || this.startDate || this.endDate || this.date_range) {
        return true;
      }
      if (this.isFiltersApplied) this.clearFilterFn();
      return false;
    },
  },
  methods: {
    async exportBalanceSheetFn() {
      if (!this.startDate && !this.endDate) {
        // const currentYear = new Date().getFullYear();
        // const end_date = `${currentYear}/12/31`;
        // const start_date = `${currentYear - 1}/01/01`;
        // this.startDate = start_date;
        // this.endDate = end_date;
      }
      let payload = {
        start_date: this.startDate,
        end_date: this.endDate,
        report_type: this.reportType,
        date_range: this.date_range,
      };

      console.log(payload);

      try {
        let { data, status } = await ExportFinancialReport(payload);
        if (status == 200) {
          console.log(baseURL);
          window.open(`${baseURL}/reports/download/${data}`);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // calReportValues(element, indexNumber, keyName, res) {
    //   const a = this.items[indexNumber].accounts.map((key) => {
    //     return `${this.currency} ${element.FinanceReportData[keyName][key]}`;
    //   });
    //   this.fields.forEach((item) => {
    //     if (item.key === `year${element.year}`) {
    //       const year = `year${element.year}`;
    //       this.items[indexNumber][year] = a;
    //     }
    //   });

    //   const lastElement = res.data[res.data.length - 1];
    //   let name = [`variance${keyName}`];
      
    //   this.items[indexNumber].Variance = Object.keys(lastElement.FinanceReportVariance).map((key) => {
       
    //     const varianceValue = lastElement.FinanceReportVariance[name][key];

    //     return `${varianceValue} %`;
    //   });
    // },

    calReportValues(element, indexNumber, keyName, res) {
  const a = this.items[indexNumber].accounts.map((key) => {
    return `${this.currency} ${element.FinanceReportData[keyName][key]}`;
  });

  this.fields.forEach((item) => {
    if (item.key === `year${element.year}`) {
      const year = `year${element.year}`;
      this.items[indexNumber][year] = a;
    }
  });

  const lastElement = res.data[res.data.length - 1];
  let name = `variance${keyName}`;

  this.items[indexNumber].Variance = [];
  const varianceKeys = Object.keys(lastElement.FinanceReportVariance[name]).sort();
  
  for (let key of varianceKeys) {
    const varianceValue = lastElement.FinanceReportVariance[name][key];
    this.items[indexNumber].Variance.push(`${varianceValue} %`);
  }
}

,
    async loadCurrentWorkspace() {
      let { data } = await GetCurrentWorkspace();
      this.currency = data.currency;
    },
    async initFn() {
      try {
        //    const currentYear = new Date().getFullYear();
        // this.currantYear = currentYear;
        // this.lastYear = currentYear - 1;
        // const end_date = `${currentYear}/12/31`;
        // const lastYear = currentYear - 1;
        // const start_date = `${lastYear}/01/01`;
        // this.startDate = start_date;
        // this.endDate = end_date;
        const res = await GetFinancialReport();
        console.log("🚀 ~ file: FinancialReport.vue:477 ~ initFn ~ res:", res);
        this.pop_data(res);
        // console.log("🚀 ~ file: FinancialReport.vue:307 ~ mounted ~ res:", res);
        // res.data.forEach((el) => {
        //   this.dataList.push(`year${el.year}`);
        //   this.dataList.push("Variance");
        //   const fieldsName = {
        //     key: `year${el.year}`,
        //   };
        //   this.fields.push(fieldsName);
        // });
        // const newFieldsName = {
        //   key: "Variance",
        // };
        // this.fields.push(newFieldsName);
        // const accounts = res.data;
        // accounts.forEach((element) => {
        //   if ("Assets" in element.FinanceReportData) {
        //     this.calReportValues(element, 0, "Assets");
        //   }
        //   if ("Liabilities" in element.FinanceReportData) {
        //     this.calReportValues(element, 1, "Liabilities");
        //   }
        //   if ("Equity" in element.FinanceReportData) {
        //     this.calReportValues(element, 2, "Equity");
        //   }
        //   if ("Income" in element.FinanceReportData) {
        //     this.calReportValues(element, 3, "Income");
        //   }
        //   if ("Expenses" in element.FinanceReportData) {
        //     this.calReportValues(element, 4, "Expenses");
        //   }
        //   const totalAccountValue = this.items[5].accounts.map((key) => {
        //     return `${this.currency} ${element[key]}`;
        //   });
        //   this.fields.forEach((item) => {
        //     if (item.key === `year${element.year}`) {
        //       const year = `year${element.year}`;
        //       this.items[5][year] = totalAccountValue;
        //     }
        //   });
        //   const a = this.items[5].accounts.map(() => {
        //     return `${this.currency} ${element.totalForAllAccounts}`;
        //   });
        //   this.items[5].Variance = a.map(() => {
        //     const value =
        //       ((this.currantYear - this.lastYear) / this.lastYear) * 100;
        //     const variance = value.toFixed(2);
        //     return variance + "%";
        //   });
        // });
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    // async exportPDFFinancesheetFn() {
    //   try {
    //     let { data, status } = await ExportFinancialReportpdf({
    //       start_date: this.startDate,
    //       end_date: this.endDate,
    //     });
    //     console.log("export ", data);
    //     if (status == 200) {
    //       console.log(baseURL);
    //       window.open(`${baseURL}/reports/download/${data}`);
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    async exportPDFFinancesheetFn() {
      let payload = {
        start_date: this.startDate,
        end_date: this.endDate,
        reportType: this.reportType,
      };
      try {
        console.log("asdasd ", payload);
        let { data, status } = await ExportFinancialReportpdf(payload);
        console.log("export ", data);
        if (status == 200) {
          console.log(baseURL);
          window.open(`${baseURL}/reports/download/${data}`);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // async pop_data(res) {
    //   try {
    //     this.fields = [
    //       {
    //         key: "accounts",
    //       },
    //     ];
    //     this.dataList = [];
    //     res.data.forEach((el) => {
    //       this.dataList.push(`year${el.year}`);
    //       this.dataList.push("Variance");
    //       const fieldsName = {
    //         key: `year ${el.year}`,
    //       };
    //       this.fields.push(fieldsName);
    //     });
    //     const newFieldsName = {
    //       key: "Variance",
    //     };
    //     this.fields.push(newFieldsName);
    //     const accounts = res.data;
    //     accounts.forEach((element) => {
    //       if ("Assets" in element.FinanceReportData) {
    //         this.calReportValues(element, 0, "Assets");
    //       }
    //       if ("Liabilities" in element.FinanceReportData) {
    //         this.calReportValues(element, 1, "Liabilities");
    //       }
    //       if ("Equity" in element.FinanceReportData) {
    //         this.calReportValues(element, 2, "Equity");
    //       }
    //       if ("Income" in element.FinanceReportData) {
    //         this.calReportValues(element, 3, "Income");
    //       }
    //       if ("Expenses" in element.FinanceReportData) {
    //         this.calReportValues(element, 4, "Expenses");
    //       }
    //       const totalAccountValue = this.items[5].accounts.map((key) => {
    //         return `${this.currency} ${element[key]}`;
    //       });
    //       this.fields.forEach((item) => {
    //         if (item.key === `year${element.year}`) {
    //           const year = `year${element.year}`;
    //           this.items[5][year] = totalAccountValue;
    //         }
    //       });
    //       const a = this.items[5].accounts.map(() => {
    //         return `${this.currency} ${element.totalForAllAccounts}`;
    //       });
    //       this.items[5].Variance = a.map(() => {
    //         const value =
    //           ((this.currantYear - this.lastYear) / this.lastYear) * 100;
    //         const variance = value.toFixed(2);
    //         return variance + "%";
    //       });
    //     });
    //   } catch (error) {
    //     this.isLoading = false;
    //     console.log(error);
    //   }
    // },
    async pop_data(res) {
      try {
        this.fields = [
          {
            key: "accounts",
          },
        ];
        this.dataList = [];
        res.data.forEach((el) => {
          if (el.year) {
            this.dataList.push(`year${el.year}`);
            this.dataList.push("Variance");
            const fieldsName = {
              key: `year${el.year}`,
            };
            this.fields.push(fieldsName);
          }
        });
        const newFieldsName = {
          key: "Variance",
        };
        this.fields.push(newFieldsName);

        const accounts = res.data;

        accounts.forEach((element) => {
          if ("Assets" in element.FinanceReportData) {
            this.calReportValues(element, 0, "Assets", res);
          }

          if ("Liabilities" in element.FinanceReportData) {
            this.calReportValues(element, 1, "Liabilities", res);
          }
          if ("Equity" in element.FinanceReportData) {
            this.calReportValues(element, 2, "Equity", res);
          }
          if ("Income" in element.FinanceReportData) {
            this.calReportValues(element, 3, "Income", res);
          }
          if ("Expenses" in element.FinanceReportData) {
            this.calReportValues(element, 4, "Expenses", res);
          }
          const totalAccountValue = this.items[5].accounts.map((key) => {
            return `${this.currency} ${element[key]}`;
          });
          this.fields.forEach((item) => {
            if (item.key === `year${element.year}`) {
              const year = `year${element.year}`;
              this.items[5][year] = totalAccountValue;
            }
          });
          const a = this.items[5].accounts.map(() => {
            return `${this.currency} ${element.totalForAllAccounts}`;
          });

          this.items[5].Variance = a.map(() => {
            if ("Assets" in element.FinanceReportData) {
              this.calReportValues(element, 0, "Assets", res);
            }
          });
        });
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    async updateReport() {
      if (
        this.startDate ||
        this.endDate ||
        this.reportType ||
        this.date_range
      ) {
        this.startDateError = true;
        this.endDateError = true;
        let payload = {
          start_date: this.startDate,
          end_date: this.endDate,
          report_type: this.reportType,
          date_range: this.date_range,
        };
        console.log("payload", payload);
        try {
          this.isLoading = true;
          this.isFiltersApplied = false;
          let res = await GetFinancialReportFilters(payload);
          this.pop_data(res);
        } catch (error) {
          this.isLoading = false;
          console.log(error);
        }
      } else {
        this.startDateError = false;
        this.endDateError = false;
      }
    },
    getFormattedNumbers(text) {
      if (!text) return "0.00";
      return formatNumbers(text);
    },
    clearFilterFn() {
      this.initFn()
      this.startDate = undefined;
      this.endDate = undefined;
      this.reportType = undefined;
      this.isFiltersApplied = false;
      this.date_range=null
    },
    formatDate(val) {
      if (val) {
        const dates = val.split("-");
        return `${dates[2]}/${dates[1]}/${dates[0]}`;
      }
    },
  },
};
</script>

<style scoped>
.mr-top {
  margin-top: 32px;
}
.hiddenText {
  opacity: 0;
}
.table-element >>> .table-header th {
  background: #0b9b7d;
  color: #ffffff;
}
.line-septate {
  border-bottom: 1px solid #bdbdbd;
}
/* insights card */
.export-btn {
  width: 160px;
  height: 50px;
  border: 1.8px solid #0b9b7d;
}

.date-picker-btn >>> .btn .b-icon.bi {
  color: #0b9b7d;
}
.b-input-group-append {
  margin-left: -1px; /* adjust as needed */
}
.date-picker-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: 1px solid #0b9b7d;
  height: 38px;
}

.filter-cards-row {
  padding: 25px;
  height: 240px;
  width: 100%;
  background-color: var(--white);
  border-radius: 5px;
  border: 1px solid var(--main-green);
  margin-bottom: 30px;
}
.filter-cards-row .date-filter-row {
  /* width: 75%; */
}
.action-btn:hover {
  background: #0b9b7d;
  box-shadow: inset 0px 0px 10px #ffffff;
  border: none;
}
/* table card */
.table-card-row {
  height: 100%;
}
/* .table-card-row .table-container-card {
  background-color: var(--white);
  width: 100%;
  min-height: 560px;
  border-radius: 5px;
  border: 1px solid var(--light);
} */
.table-card-row .table-container-card >>> .tab-wrapper {
  /* border-bottom: 0.5px solid var(--gray-750); */
  border-bottom: none;
}
.table-card-row .table-container-card >>> .invoice-tabs {
  width: 100%;
  font-size: 1rem;
  overflow: hidden;
  /* background-color: var(--ghost-white); */
}
.table-card-row .table-container-card >>> .invoice-tabs .tab-nav-link {
  font-size: 0.9rem;
  padding-right: 20px;
  white-space: nowrap;
  background: transparent;
  border: transparent;
  color: var(--gray-750);
  /* border-bottom: 1px solid var(--prime-gray); */
}
.table-card-row .table-container-card >>> .invoice-tabs .tab-nav-link:hover {
  background-color: var(--light);
  color: var(--primary);
}
.table-card-row .table-container-card >>> .invoice-tabs .tab-nav-link.active {
  background-color: var(--light);
  border-bottom: 2px solid var(--primary);
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .filter-cards-row {
    min-height: 270px;
    padding: 20px 5px;
    margin: 20px 5px;
    width: 100%;
  }
  .filter-cards-row .date-filter-row {
    width: 100%;
  }
}
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .filter-cards-row {
    min-height: 360px;
    padding: 20px 0px;
    margin: 5px;
    width: 100%;
  }
  .filter-cards-row .date-filter-row {
    width: 100%;
  }
}
</style>
