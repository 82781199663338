<template>
  <b-modal
    id="create-asset-modal"
    ref="CreateAssetModal"
    title="Create Journal"
    size="lg"
    header-class="font-primary"
    header-bg-variant="light"
    header-text-variant="main-green"
    header-border-variant="white"
    footer-bg-variant="transparent"
    footer-border-variant="white"
    no-close-on-esc
    no-close-on-backdrop
    @hide="closeModal()"
  >
    <template v-slot:modal-title>
      <template>
        <span class="ml-4 text-main-green" v-if="!itemContent"
          >Create Fixed Assets</span
        >
        <span class="ml-4 text-main-green" v-else>Update Fixed Assets</span>
      </template>
    </template>
    <template v-slot:modal-footer="{ cancel }">
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <div
        class="d-flex flex-column flex-sm-row align-items-end justify-content-end mx-0 mb-3 w-100"
      >
        <b-button
          variant="gray-550"
          pill
          class="my-2 my-sm-0 mr-0 mr-sm-2 text-white border-0 bg-gray-550 action-btn"
          style="width: 110px"
          @click="cancel"
        >
          Cancel
        </b-button>

        <b-button
          v-if="!itemContent"
          variant="main-green"
          pill
          class="my-2 my-sm-0 ml-0 border-0 action-btn"
          style="width: 110px"
          @click="addFixedAsset()"
        >
          Submit
        </b-button>
        <b-button
          v-else
          variant="main-green"
          pill
          class="my-2 my-sm-0 ml-0 border-0 action-btn"
          style="width: 110px"
          @click="addFixedAsset()"
        >
          Update
        </b-button>
      </div>
    </template>
    <!-- custom close icon -->
    <template v-slot:modal-header-close>
      <b-icon
        icon="x-circle"
        class="text-dark"
        @click="closeModal()"
        aria-label="Close Modal"
        font-scale="0.8"
      ></b-icon>
    </template>
    <b-row
      class="d-flex flex-row align-items-start justify-content-start w-100 py-1 mb-3 mb-md-2"
      :class="'mt-3'"
      no-gutters
    >
      <b-row
        class="d-flex flex-row align-items-start justify-content-center w-100 pb-2 px-3"
        no-gutters
      >
        <b-col>
          <FormInput
            groupId="code-group"
            id="code-group-input"
            class="text-prime-gray rounded-2"
            :state="assetNameError.status"
            :invalidFeedback="`${assetNameError.message}`"
            label="Asset Name"
            v-model="assetname"
            :whiteBG="false"
            form="login-form"
          ></FormInput>
        </b-col>
        <b-col class="ml-md-4">
          <FormInput
            groupId="code-group"
            id="code-group-input"
            class="text-prime-gray rounded-2"
            :state="assetNumberError.status"
            :invalidFeedback="`${assetNumberError.message}`"
            label="Asset Number"
            v-model="assetnumber"
            :whiteBG="false"
            form="login-form"
          ></FormInput>
        </b-col>
      </b-row>
      <b-row
        class="d-flex flex-row align-items-start justify-content-center w-100 pb-2 px-3"
        no-gutters
      >
        <b-col  v-if="!itemContent">
          <FormSelect
            groupId="code-group"
            id="code-group-input"
            class="text-prime-gray rounded-2"
            :state="assetTypeError.status"
            :invalidFeedback="`${assetTypeError.message}`"
            label="Asset Type"
            v-model="assettype"
            :options="accountTypeOptions"
            :whiteBG="false"
            form="login-form"
          ></FormSelect>
        </b-col>
        <b-col v-else>
          <FormSelect
          :isDisabled="itemContent != null"
            groupId="code-group"
            id="code-group-input"
            class="text-prime-gray rounded-2"
            :state="assetTypeError.status"
            :invalidFeedback="`${assetTypeError.message}`"
            label="Asset Type"
            v-model="assettype"
            :options="accountTypeOptions"
            :whiteBG="false"
            form="login-form"
          ></FormSelect>
        </b-col>
        <b-col class="ml-md-4">
          <b-input-group>
            <b-form-input
              id="example-input"
              :value="formatDate(purchasedDate)"
              type="text"
              class="date-fields-style date-picker"
              style="height: 40px"
              placeholder="Purchased Date"
              autocomplete="off"
              :state="purchasedDateError.status"
              :invalidFeedback="`${purchasedDateError.message}`"
              :class="
                purchasedDateError.status != false
                  ? 'border-main-green'
                  : 'border-danger'
              "
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                class="date-picker-btn font-primary datepicker align-items-center"
                v-model="purchasedDate"
                no-flip
                size="sm"
                button-only
                button-variant="white"
                locale="en-US"
                right
                nav-button-variant="main-green"
                today-variant="main-green"
                menu-class=" rounded-lg custom-calendar-dropdown text-prime-gray"
                aria-controls="start-date-input"
              ></b-form-datepicker>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row
        class="d-flex flex-row align-items-start justify-content-center w-100 pb-2 px-3"
        no-gutters
      >
        <b-col>
          <FormInput
            groupId="code-group"
            id="code-group-input"
            class="text-prime-gray rounded-2"
            :state="purchasePriceError.status"
            :invalidFeedback="`${purchasePriceError.message}`"
            label="Purchased Price"
            v-model="purchasedPrice"
            :whiteBG="false"
            form="login-form"
            type="number"
          ></FormInput>
        </b-col>
        <b-col class="ml-md-4">
          <FormInput
            groupId="code-group"
            id="code-group-input"
            class="text-prime-gray rounded-2"
            :state="bookValueError.status"
            :invalidFeedback="`${bookValueError.message}`"
            label="Book Value"
            v-model="bookValue"
            :whiteBG="false"
            form="login-form"
          ></FormInput>
        </b-col>
      </b-row>
      <b-row
        class="d-flex flex-row align-items-start justify-content-center w-100 pb-2 px-3"
        no-gutters
      >
        <b-col>
          <FormInput
            groupId="code-group"
            id="code-group-input"
            class="text-prime-gray rounded-2"
            :state="serialNumberError.status"
            :invalidFeedback="`${serialNumberError.message}`"
            label="Serial No./Asset Tag (Optional)"
            v-model="serialNumber"
            :whiteBG="false"
            form="login-form"
          ></FormInput>
        </b-col>
        <b-col class="ml-md-4">
          <b-input-group>
            <b-form-input
              id="example-input"
              :value="formatDate(warrentyExpire)"
              type="text"
              class="date-fields-style date-picker"
              style="height: 40px"
              placeholder="Warranty Expiry"
              :state="warrentyExpireDateError.status"
              :invalidFeedback="`${warrentyExpireDateError.message}`"
              autocomplete="off"
              :class="
                warrentyExpireDateError.status != false
                  ? 'border-main-green'
                  : 'border-danger'
              "
              
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                class="date-picker-btn font-primary datepicker align-items-center"
                v-model="warrentyExpire"
                :min="minDate"
                no-flip
                size="sm"
                button-only
                button-variant="white"
                locale="en-US"
                right
                nav-button-variant="main-green"
                today-variant="main-green"
                menu-class=" rounded-lg custom-calendar-dropdown text-prime-gray"
                aria-controls="start-date-input"
              ></b-form-datepicker>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row
        class="d-flex flex-row align-items-start justify-content-center w-100 pb-2 px-3"
        no-gutters
      >
        <b-col cols="12">
          <FormInput
            groupId="user-name-group"
            id="user-name-group-input"
            class="text-prime-gray rounded-2"
            v-model="assetDescription"
            label="Description"
            placeholder="Write a Description"
            isRequired
            :whiteBG="false"
            trim
            form="login-form"
            type="text"
          ></FormInput>
        </b-col>
      </b-row>
    </b-row>
    <b-row
      class="d-flex flex-row align-items-start justify-content-center w-100 pb-2 px-3"
      no-gutters
    >
      <b-col>
        <FormSelect
          groupId="type-group"
          id="type-group-input"
          class="text-prime-gray rounded-2"
          label="Asset Account"
          v-model="assetAccount"
          :whiteBG="false"
          :options="AssetAccountOptions"
          form="login-form"
          placeholder="Default"
        ></FormSelect>
      </b-col>
      <b-col class="ml-md-4">
        <b-input-group>
          <b-form-input
            id="example-input"
            :value="formatDate(depricationStartDate)"
            type="text"
            class="border-main-green date-fields-style date-picker"
            style="height: 40px"
            placeholder="Depreciation Start Date"
            autocomplete="off"
          ></b-form-input>
          <b-input-group-append>
            <b-form-datepicker
              class="date-picker-btn font-primary datepicker align-items-center"
              v-model="depricationStartDate"
              no-flip
              size="sm"
              button-only
              button-variant="white"
              locale="en-US"
              right
              nav-button-variant="main-green"
              today-variant="main-green"
              menu-class=" rounded-lg custom-calendar-dropdown text-prime-gray"
              aria-controls="start-date-input"
            ></b-form-datepicker>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row
      class="d-flex flex-row align-items-start justify-content-center w-100 pb-2 px-3"
      no-gutters
    >
      <b-col>
        <FormSelect
          groupId="code-group"
          id="code-group-input"
          class="text-prime-gray rounded-2"
          label="Depreciation Method"
          v-model="depricationMethod"
          :options="DepreciationOptions"
          :whiteBG="false"
          form="login-form"
        ></FormSelect>
      </b-col>
      <b-col class="ml-md-4">
        <FormSelect
          groupId="type-group"
          id="type-group-input"
          class="text-prime-gray rounded-2"
          label="Depreciation Frequency"
          v-model="monthly"
          :whiteBG="false"
          :options="MonthlyOptions"
          form="login-form"
          placeholder="Default"
        ></FormSelect>
      </b-col>
    </b-row>
    <b-row
      class="d-flex flex-row align-items-start justify-content-center w-100 pb-2 px-3"
      no-gutters
    >
      <b-col>
        <FormInput
          groupId="code-group"
          id="code-group-input"
          class="text-prime-gray rounded-2"
          label="Average Method Rate (%)"
          v-model="averageMethodRate"
          :whiteBG="false"
          form="login-form"
        ></FormInput>
      </b-col>
      <b-col class="ml-md-4">
        <FormInput
          groupId="code-group"
          id="code-group-input"
          class="text-prime-gray rounded-2"
          label="Effective Life in years"
          v-model="effectiveLifeYears"
          :whiteBG="false"
          form="login-form"
        ></FormInput>
      </b-col>
    </b-row>
    <b-row
      class="d-flex flex-row align-items-start justify-content-center w-100 pb-2 px-3"
      no-gutters
    >
      <b-col>
        <FormInput
          groupId="code-group"
          id="code-group-input"
          class="text-prime-gray rounded-2"
          label="Location Code"
          v-model="locationCode"
          :whiteBG="false"
          form="login-form"
        ></FormInput>
      </b-col>
      <b-col class="ml-md-4">
        <FormInput
          groupId="code-group"
          id="code-group-input"
          class="text-prime-gray rounded-2"
          label="Department"
          v-model="department"
          :whiteBG="false"
          form="login-form"
        ></FormInput>
      </b-col>
    </b-row>
    <!-- shipping address checkbox  -->
    <b-row
      class="d-flex flex-row align-items-start justify-content-start w-100 px-2 py-3"
      no-gutters
    >
      <b-col class="d-flex flex-column align-items-start justify-content-start">
        <b-form-checkbox
          id="checkbox-1"
          v-model="disposalStatus"
          name="checkbox-1"
          value="isDisposal"
          unchecked-value="isDisposalFalse"
        >
          Disposal of Asset
        </b-form-checkbox>
      </b-col>
    </b-row>
    <template v-if="this.disposalStatus == `isDisposal`">
      <b-row
        class="d-flex flex-row align-items-start justify-content-center w-100 pb-2 px-3"
        no-gutters
      >
        <b-col>
          <FormInput
            groupId="code-group"
            id="code-group-input"
            class="text-prime-gray rounded-2"
            :state="soldPriceError.status"
            :invalidFeedback="`${soldPriceError.message}`"
            label="Sold Price"
            v-model="soldPrice"
            :whiteBG="false"
            form="login-form"
          ></FormInput>
        </b-col>
        <b-col class="ml-md-4">
          <b-input-group>
            <b-form-input
              id="example-input"
              :value="formatDate(soldDate)"
              type="text"
              class="border-main-green date-fields-style date-picker"
              style="height: 40px"
              placeholder="Sold Date"
              autocomplete="off"
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                class="date-picker-btn font-primary datepicker align-items-center"
                v-model="soldDate"
                no-flip
                size="sm"
                button-only
                button-variant="white"
                locale="en-US"
                right
                nav-button-variant="main-green"
                today-variant="main-green"
                menu-class=" rounded-lg custom-calendar-dropdown text-prime-gray"
                aria-controls="start-date-input"
              ></b-form-datepicker>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </template>
    <!-- <b-row
        class="d-flex flex-row align-items-start justify-content-center w-100 pb-2 px-3"
        no-gutters
      >
        <b-col 
       >
       <FormInput
            groupId="code-group"
            id="code-group-input"
            class="text-prime-gray rounded-2"
            :state="codeError.status"
            :invalidFeedback="`${codeError.message}`"
            label="Code"
            v-model="code"
            :whiteBG="false"
            form="login-form"
          ></FormInput>
        </b-col>
        <b-col
        class="ml-md-4">
        <FormInput
            groupId="code-group"
            id="code-group-input"
            class="text-prime-gray rounded-2"
            :state="codeError.status"
            :invalidFeedback="`${codeError.message}`"
            label="Code"
            v-model="code"
            :whiteBG="false"
            form="login-form"
          ></FormInput>
        >
       
        </b-col>
      </b-row> -->
  </b-modal>
</template>
<script>
import FormInput from "@/components/Form/FormInput";
import FormSelect from "@/components/Form/FormSelect";
import { AddFixedAsset, UpdateAsset } from "@/services/fixedassets.service";
import { GetAssetDetails } from "@/services/fixedassets.service";
import {
  AddChartOfAccount,
  UpdateChartOfAccount,
} from "@/services/transactions.service";
import { GetAllChartOfAccounts } from "@/services/transactions.service";
export default {
  name: "AddAssetModal",

  props: {
    isEdit: {
      default: false,
      type: Boolean,
    },
    itemContent: {
      default: null,
    },
  },
  data() {
    return {
      Created_COA_id:null,
      isDuplicateCOA:false,
      accounts: {
        type: Object,
      },
      COA_id: null,
      minDate:null,
      assetname: null,
      assetnumber: null,
      assettype: null,
      purchasedDate: null,
      purchasedPrice: null,
      bookValue: null,
      serialNumber: null,
      warrentyExpire: null,
      description: null,
      assetAccount: null,
      depricationStartDate: null,
      assetDescription: null,
      depricationMethod: null,
      monthly: null,
      averageMethodRate: null,
      effectiveLifeYears: null,
      locationCode: null,
      department: null,
      disposalStatus: "isDisposal",
      soldPrice: null,
      soldDate: null,
      searchTable: null,
      isLoading: false,
      accountTypeOptions: [],
      // accountTypeOptions: [
      //   "Cash and bank",
      //   "Money in Transit",
      //   "Expected payments from customers",
      //   "Inventory",
      //   "Property, Plant, Equipment",
      //   "Depreciation and Amortization",
      //   "Vendor Prepayments and Vendor Credits",
      //   "Other Short-Term Asset",
      //   "Other Long-Term Asset",
      // ],
      recurringOptions: ["Monthly", "Quarterly"],
      recurring: "Monthly",
      AssetTypeOptions: [
        "Buildings",
        "Leasehold Improvements",
        "Plant and Equipment",
        "IT Equipment",
        "Furniture and Software",
      ],
      DepreciationOptions: [
        "Straight Line Depreciation",
        "Diminishing Value Depreciation",
      ],
      AssetAccountOptions: [
        "Asset Account",
        "Accumulated Depreciation Account",
        "Expense Account",
      ],

      MonthlyOptions: ["Monthly", "Yearly"],

      assetNameError: {
        status: null,
        message: "",
      },
      assetNumberError: {
        status: null,
        message: "",
      },
      assetTypeError: {
        status: null,
        message: "",
      },

      purchasePriceError: {
        status: null,
        message: "",
      },
      bookValueError: {
        status: null,
        message: "",
      },
      serialNumberError: {
        status: null,
        message: "",
      },
      soldPriceError: {
        status: null,
        message: "",
      },
      purchasedDateError: {
        status: null,
        message: "",
      },
      warrentyExpireDateError: {
        status: null,
        message: "",
      },
    };
  },
  async mounted() {
    this.setDate()
    await this.initAndAccountType();
    console.log("isEditmount", this.isEdit);
  },

  watch: {
    itemContent(val) {
      console.log("object", val);
      if (val) {
        this.onModalShow();
      } else {
        this.resetFn();
      }
    },
    assetname(value) {
      if (value) {
        this.assetNameError.status = true;
      } else {
        this.assetNameError.status = false;
        this.assetNameError.message = "Please fill out this field";
      }
    },
    assetnumber(value) {
      if (value) {
        this.assetNumberError.status = true;
      } else {
        this.assetNumberError.status = false;
        this.assetNumberError.message = "Please fill out this field";
      }
    },
    assettype(value) {
      if (value) {
        this.assetTypeError.status = true;
      } else {
        this.assetTypeError.status = false;
        this.assetTypeError.message = "Please fill out this field";
      }
    },
    purchasedDate(value) {
      if (value) {
        this.purchasedDateError.status = true;
      } else {
        this.purchasedDateError.status = false;
        this.purchasedDateError.message = "Please fill out this field";
      }
    },
    purchasedPrice(value) {
      if (value) {
        this.purchasePriceError.status = true;
      } else {
        this.purchasePriceError.status = false;
        this.purchasePriceError.message = "Please fill out this field";
      }
    },
    bookValue(value) {
      if (value) {
        this.bookValueError.status = true;
      } else {
        this.bookValueError.status = false;
        this.bookValueError.message = "Please fill out this field";
      }
    },
    // serialNumber(value) {
    //   if (value) {
    //     this.serialNumberError.status = true;
    //   } else {
    //     this.serialNumberError.status = false;
    //     this.serialNumberError.message = "Please fill out this field";
    //   }
    // },
    warrentyExpire(value) {
      if (value) {
        this.warrentyExpireDateError.status = true;
      } else {
        this.warrentyExpireDateError.status = false;
        this.warrentyExpireDateError.message = "Please fill out this field";
      }
    },
    depricationMethod(newVal) {
      if (newVal === "Diminishing Value Depreciation") {
        // this.monthly = 3;
        this.MonthlyOptions = [3, 4, 5, 6];
      } else if (newVal === "Straight Line Depreciation") {
        // this.monthly = 'Monthly';
        this.MonthlyOptions = ["Monthly", "Yearly"];
      }
    },
  },

  components: {
    FormInput,
    FormSelect,
  },

  methods: {
    closeModal(){
     this.resetFn()
    },
    setDate(){
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      const minDate = new Date(today)
      minDate.setMonth(minDate.getMonth())
      minDate.setDate(today.getDate())
      this.minDate = minDate
    },
    async initAndAccountType() {
      try {
        this.isLoading = true;
        this.totalAccountCount = 0;
        let { data } = await GetAllChartOfAccounts();
        this.accounts = data.chart.chart.assets.sub;
        let accountsArray = [];

        // Iterate over the keys in this.accounts and add them to accountsArray
        for (const key in this.accounts) {
          accountsArray.push(key);
        }
        this.accountTypeOptions = accountsArray;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },

    async addFixedAsset() {
      if (this.itemContent) {
        if (
          this.assetname &&
          this.assetnumber &&
          this.assettype &&
          this.purchasedDate &&
          this.purchasedPrice &&
          this.bookValue &&
          // this.serialNumber &&
          this.warrentyExpire
        ) {
          let payload = {
            id: this.itemContent.AssetID,
            name: this.assetname,
            number: this.assetnumber,
            type: this.assettype,
            purchased_date: this.purchasedDate,
            purchased_price: this.purchasedPrice,
            book_value: this.bookValue,
            serial_number: this.serialNumber,
            warrenty_expiry: this.warrentyExpire,
            discription: this.assetDescription,
            asset_account: this.assetAccount,
            depreciation_start_date: this.depricationStartDate,
            depreciation_method: this.depricationMethod,
            monthly: this.monthly,
            averaging_method_rate: this.averageMethodRate,
            effective_life_in_years: this.effectiveLifeYears,
            location_code: this.locationCode,
            department: this.department,
            sold_price: this.soldPrice,
            sold_date: this.soldDate,
          };
          let payload2 = {
            type: "assets",
            subcategory: this.assettype,
            name: this.assetname,
          };
          console.log("payload", payload);

          try {
            let { data } = await UpdateAsset(payload);
            let { data2 } = await UpdateChartOfAccount(payload2, this.COA_id);
            if (data.msg == "Updated succesfully") {
              console.log("Updated", data);
              console.log("Updated", data2);
              // this.$router.go();
              let payloadNotify = {
              isToast: true,
              title: "Fixed Assert",
              content:'Fixed assert updated successfully',
              variant: "success",
              };

              this.$store.dispatch("notification/setNotify", payloadNotify);
              this.$emit("reloadFn");
              this.resetFn();
              this.$bvModal.hide("create-asset-modal");
            }
          } catch (error) {
              let payloadNotify = {
              isToast: true,
              title: "Fixed Assert",
              content:error,
              variant: "danger",
            };
            this.$store.dispatch("notification/setNotify", payloadNotify);
          }
        } else {
          this.validateForm();
        }
      } else {
        if (
          this.assetname &&
          this.assetnumber &&
          this.assettype &&
          this.purchasedDate &&
          this.purchasedPrice &&
          this.bookValue &&
          // this.serialNumber &&
          this.warrentyExpire
        ) {
          let payload = {
            name: this.assetname,
            number: this.assetnumber,
            type: this.assettype,
            purchased_date: this.purchasedDate,
            purchased_price: this.purchasedPrice,
            book_value: this.bookValue,
            serial_number: this.serialNumber,
            warrenty_expiry: this.warrentyExpire,
            discription: this.assetDescription,
            asset_account: this.assetAccount,
            depreciation_start_date: this.depricationStartDate,
            depreciation_method: this.depricationMethod,
            monthly: this.monthly,
            averaging_method_rate: this.averageMethodRate,
            effective_life_in_years: this.effectiveLifeYears,
            location_code: this.locationCode,
            department: this.department,
            sold_price: this.soldPrice,
            sold_date: this.soldDate,
          };

          console.log("payload", payload);

          let payload2 = {
            type: "assets",
            subcategory: this.assettype,
            name: this.assetname,
          };

          try {
            let data2
            if(!this.isDuplicateCOA){
             data2 = await AddChartOfAccount(payload2);
             this.Created_COA_id=data2.data.data._id
            }
            payload.COA_id = this.isDuplicateCOA?this.Created_COA_id: data2.data.data._id;
            if (data2?.data.msg==='Account added succesfully' ||  this.isDuplicateCOA) {
              this.isDuplicateCOA=true
              await AddFixedAsset(payload)
              // this.$router.go();
              let payloadNotify = {
              isToast: true,
              title: "Fixed Assert",
              content:'Fixed assert created successfully',
              variant: "success",
             };
              this.$store.dispatch("notification/setNotify", payloadNotify);
              this.$emit("reloadFn");
              this.resetFn();
              this.isDuplicateCOA=false
              this.Created_COA_id=null
              this.$bvModal.hide("create-asset-modal");
            }
          } catch (error) {
            let payloadNotify = {
            isToast: true,
            title: "Fixed Assert",
            content:error,
            variant: "danger",
           };
          
           this.$store.dispatch("notification/setNotify", payloadNotify);
          }
        } else {
          this.validateForm();
        }
      }
    },

    async onModalShow() {
      try {
        //  let payload = {
        //    id: this.assetId,
        //  };
        this.isLoading = true;
        console.log("asset Id", this.itemContent.AssetID);

        let { data } = await GetAssetDetails({ id: this.itemContent.AssetID });
        this.COA_id = data.COA_id;
        this.assetname = data.name;
        this.assetnumber = data.number;
        this.assettype = data.type;
        this.purchasedDate = data.purchased_date;
        this.purchasedPrice = data.purchased_price;
        this.bookValue = data.book_value;
        this.serialNumber = data.serial_number;
        this.warrentyExpire = data.warrenty_expiry;
        this.assetDescription = data.discription;
        this.assetAccount = data.asset_account;
        this.depricationStartDate = data.depreciation_start_date;
        this.depricationMethod = data.depreciation_method;
        this.monthly = data.monthly;
        this.averageMethodRate = data.averaging_method_rate;
        this.effectiveLifeYears = data.effective_life_in_years;
        this.locationCode = data.location_code;
        this.department = data.department;
        this.soldPrice = data.sold_price;
        this.soldDate = data.sold_date;

        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    validateForm() {
      if (!this.assetname) {
        this.assetNameError.status = false;
        this.assetNameError.message = "Please fill out this field";
      }

      if (!this.assetnumber) {
        this.assetNumberError.status = false;
        this.assetNumberError.message = "Please fill out this field";
      }

      if (!this.assettype) {
        this.assetTypeError.status = false;
        this.assetTypeError.message = "Please fill out this field";
      }

      if (!this.purchasedDate) {
        this.purchasedDateError.status = false;
        this.purchasedDateError.message = "Please fill out this field";
      }

      if (!this.purchasedPrice) {
        this.purchasePriceError.status = false;
        this.purchasePriceError.message = "Please fill out this field";
      }

      if (!this.bookValue) {
        this.bookValueError.status = false;
        this.bookValueError.message = "Please fill out this field";
      }

      // if (!this.serialNumber) {
      //   this.serialNumberError.status = false;
      //   this.serialNumberError.message = "Please fill out this field";
      // }

      if (!this.warrentyExpire) {
        this.warrentyExpireDateError.status = false;
        this.warrentyExpireDateError.message = "Please fill out this field";
      }
    },
    formatDate(val) {
      if (val) {
        const dateObj = new Date(val);
        const year = dateObj.getFullYear();
        const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
        const day = dateObj.getDate().toString().padStart(2, "0");
        return `${day}/${month}/${year}`;
      }
    },
    resetFn() {
      (this.assetname = null),
        (this.assetnumber = null),
        (this.assettype = null),
        (this.purchasedDate = null),
        (this.purchasedPrice = null),
        (this.bookValue = null),
        (this.serialNumber = null),
        (this.warrentyExpire = null),
        (this.description = null),
        (this.assetAccount = null),
        (this.depricationStartDate = null),
        (this.assetDescription = null),
        (this.depricationMethod = null),
        (this.monthly = null),
        (this.averageMethodRate = null),
        (this.effectiveLifeYears = null),
        (this.locationCode = null),
        (this.department = null),
        (this.soldPrice = null),
        (this.soldDate = null);
    },
  },
};
</script>
<style scoped>
.types {
  width: 215px !important;
  margin: 0 20px !important;
}
::placeholder {
  color: #0b9b7d;
  opacity: 1;
}
.icon-color {
  color: #1c72ff;
}

.text-color {
  color: #1c72ff;
}

.border-color {
  border: 1px solid #1c72ff;
}

.date-fields-style {
  border: 1.8px solid #0b9b7d;
}

.desWidth {
  width: 80%;
}

.count-style {
  border-radius: 30px;
  width: 30px;
  height: 30px;
  color: #0b9b7d;
  background-color: #f7fbfc;
  font-weight: 500;
  margin-top: -1px;
  padding-left: 10px;
  padding-top: 2px;
}

.disabled-button {
  background-color: #0b9b7d;
  background: rgba(33, 150, 83, 0.5);
  color: #ffffff;
  border-color: #ffffff;
}

.date-picker-btn >>> .btn .b-icon.bi {
  color: #0b9b7d;
}

.date-picker-btn {
  border: 1.8px solid;
  border-color: #0b9b7d;
  border-radius: 0px 3px 3px 0px;
}

.account-dropdown {
  max-height: 240px;
  overflow: auto;
  scrollbar-width: thin;
}

.account-dropdown::-webkit-scrollbar {
  width: 6px;
}

.account-dropdown::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(33, 150, 83, 0.5);
  border-radius: 5px;
}

.account-dropdown::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px #0b9b7d;
}

/* table card */
.table-card-row {
  height: 100%;
}

/* .table-card-row .type-filter {
  width: 100%;
} */
.table-card-row .table-container-card {
  background-color: var(--white);
  width: 100%;
  min-height: 400px;
  border-radius: 0;
  border: 1px solid var(--light);
}

/* table actions row */
.table-card-row .search-input-group {
  box-shadow: none;
  height: 50px;
  min-width: 200px;
}

.table-card-row .search-input-group .search-input {
  box-shadow: none;
  height: 50px;
}

.table-card-row .search-input-group .search-input::placeholder {
  color: #0b9b7d;
}

.table-card-row .category-dropdown,
.table-card-row .types-dropdown,
.table-card-row .accounts-dropdown,
.table-card-row .date-picker,
.table-card-row .add-question-btn {
  height: 45px;
  /* width: 100px; */
}

.table-card-row .add-question-btn:hover {
  background: #0b9b7d;
  box-shadow: inset 0px 0px 10px #ffffff;
  color: #ffffff;
  border: none;
}

.table-card-row .accounts-dropdown {
  min-width: 295px;
}

.table-card-row .category-dropdown {
  min-width: 310px;
  padding-left: 15px;
}

.table-card-row .types-dropdown {
  min-width: 310px;
  /* margin-right: 30px; */
}

.table-card-row .startdate-picker {
  width: 200px;
  border: 1.8px solid #0b9b7d;
  color: #0b9b7d;
}

.table-card-row .enddate-picker {
  width: 200px;
  border: 1.8px solid #0b9b7d;
}

/* date picker */
.table-card-row .b-form-datepicker.form-control:focus {
  color: #0b9b7d;
  background-color: #fff;
  border-color: #0b9b7d;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(33, 150, 83, 0.5);
}

/* date selector calendar dialog */
.table-card-row
  .b-form-datepicker
  >>> .custom-calendar-dropdown
  .b-calendar-grid.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #0b9b7d;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(33, 150, 83, 0.5);
}

.table-card-row .category-dropdown .types-dropdown >>> .filter-dropdown-menu {
  height: auto;
  min-width: 200px;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}

.table-card-row .types-dropdown >>> .filter-dropdown-menu {
  height: auto;
  min-width: 200px;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}

.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item.active,
.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item:active {
  background-color: var(--light);
}

/* table container */
.table-container-row {
  height: 100%;
  width: 50%;
  overflow-y: hidden;
  scrollbar-width: thin;
}

.table-card-row .apply-filters-btn {
  transition: color 200ms ease-in;
  /* color: var(--secondary); */
  /* border: none; */
}

.table-card-row .apply-filters-btn:hover {
  background-color: #0b9b7d;
  /* box-shadow: inset 0px 0px 10px #ffffff; */
  /* color: var(--primary); */
}

.clearAll-text-color {
  color: #0b9b7d;
}

.clearAll-text-color:hover {
  color: #0b9b7d;
}

.vertical-bar {
  margin-right: 19px;
  margin-left: 5px;
  margin-top: 2px;
  color: #bdbdbd;
}

.table-container-row .load-more-btn {
  transition: color 200ms ease-in;
  color: #0b9b7d;
}

.table-container-row .load-more-btn:hover {
  color: #0b9b7d;
  text-decoration: none;
}

/* .table-container-row .table-action-row {
  height: 60px;
  padding-top: 20px;
} */
/* scrollbar size fix for webkit browsers (chrome/safari) */
.table-container-row::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}

.table-container-row::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(33, 150, 83, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}

.table-container-row::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}

.table-container-row .table-element {
  min-width: 500px;
  width: 100%;
  height: auto;
  scrollbar-width: thin;
}

/* table element css */
.table-container-row .table-element >>> .table-header {
  /* background-color: #f7f7ff; */
  background-color: transparent;
}

.table-container-row .table-element >>> .table-header th {
  color: var(--prime-gray);
  font-size: 0.8rem;
  font-weight: 400;
  border-color: transparent;
}

/* .table-element >>> .table-body td {
  vertical-align: middle;
  
} */

.creditDebit-table .table-body td,
.creditDebit-table .table-body th {
  padding: 0px;
}
.table th,
.table td {
  padding: 0;
}
.table td,
.table th {
  padding: 0;
}
/* .table-element >>> .table-body tr:nth-child(even) {
  vertical-align: middle;
  background-color: var(--light);
} */
.table-element >>> .table-body .main-text {
  font-size: 0.9rem;
}

.table-element >>> .table-body .sub-text {
  font-size: 0.7rem;
}

/* question row */
.table-element >>> .table-body .question-row {
  max-width: 550px;
}

/* actions row */
.table-element >>> .table-body .actions-row {
  min-width: 140px;
}

/* more icon button (default state) */
.table-element >>> .table-body .more-btn {
  width: 35px;
  height: 35px;
  padding: 7px;
  background-color: #0b9b7d;
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .more-btn > .more-icon {
  color: var(--white);
  transition: color 200ms ease-in;
}

/* more icon button (hover state) */
.table-element >>> .table-body .more-btn:hover {
  background-color: var(--prime-gray);
}

.table-element >>> .table-body .more-btn:hover > .more-icon {
  color: var(--white);
}

/* table question action buttons */
/* edit icon button (default state) */
.table-element >>> .table-body .edit-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  border: hidden;
  /* background-color: var(--white); */
  background-color: transparent;
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .edit-btn > .edit-icon {
  color: #0b9b7d;
  transition: color 200ms ease-in;
}

.table-element >>> .table-body .edit-btn > .reviewed-icon {
  color: var(--success);
  transition: color 200ms ease-in;
}

/* edit icon button (hover state) */
.table-element >>> .table-body .edit-btn:hover {
  /* background-color: var(--info); */
}

.table-element >>> .table-body .edit-btn:hover > .edit-icon {
  color: #0b9b7d;
}

.table-element >>> .table-body .b-table-details {
  background-color: #fff;
}

.table-element >>> .table-body .b-table-details:hover {
  cursor: auto;
  background-color: #fff;
}

@media (max-width: 1346px) and (min-width: 1170px) {
  .category-field {
    max-width: 350px;
  }

  .type-field {
    max-width: 200px;
  }
}

@media (max-width: 1170px) and (min-width: 1118px) {
  .category-field {
    max-width: 330px;
  }

  .type-field {
    max-width: 200px;
  }
}

@media (max-width: 1118px) and (min-width: 1060px) {
  .category-field {
    max-width: 310px;
  }

  .type-field {
    max-width: 188px;
  }
}

@media (max-width: 1060px) and (min-width: 992px) {
  .category-field {
    max-width: 270px;
  }

  .type-field {
    max-width: 160px;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .table-card-row .search-input-group {
    max-width: none;
  }

  .table-card-row .category-dropdown {
    min-width: 330px;
  }

  .table-card-row .types-dropdown {
    min-width: 330px;
  }
}
</style>