<template>
  <HomeLayout>
    <b-container
      class="d-flex flex-column align-items-start justify-content-start px-0 root-container"
    >
      <!-- title row -->
      <b-row
        class="d-flex flex-row align-items-stretch justify-content-center w-100 px-3 mt-3"
        no-gutters
        style="cursor:pointer;"
      >
        <b-card
          no-body
          class="position-relative overflow-hidden border-1 border-main-green report-card-row w-100"
        >
        <b-link
                  class="text-left font-primary font-weight-normal title-btn " 
                  to="/reports/profit-and-loss"
                  :disabled="
                    !allowedReports.includes('Profit and Lost') &&
                      !allowedReports.includes('All')
                  "
      >
          <b-row
            class="d-flex flex-row align-items-center justify-content-start my-3 my-md-2 px-4 w-100"
            no-gutters
          >
            <!-- title column  -->
            <b-col
              class="d-flex flex-column align-items-start justify-content-center"
              cols="11"
              md="8"
            >
              <b-row
                class="d-flex flex-row align-items-center justify-content-center"
                no-gutters
              >
                <h4 class="pl-2 font-weight-bold">Profit & Loss</h4>
              </b-row>
              <b-row
                class="d-flex flex-row align-items-center justify-content-start w-100"
                no-gutters
              >
                <span class="text-gray-750 pl-2">
                  Summary of your revenue and expenses that determine the profit
                  you made.
                </span>
              </b-row>
            </b-col>
            <!-- icon column  -->
            <b-col
              class="d-flex flex-column align-items-end justify-content-center"
              cols="1"
              md="4"
            >
              <b-row
                class="d-flex flex-row align-items-center justify-content-center border-0 rounded-right"
                no-gutters
              >
                <b-icon
                  v-if="
                    !allowedReports.includes('Profit and Lost') &&
                      !allowedReports.includes('All')
                  "
                  icon="lock-fill"
                  class=""
                  scale="1.1"
                  aria-hidden="true"
                  variant="warning"
                  title="This feature is not available in your package"
                ></b-icon>
                <b-link
                  class="text-left font-primary font-weight-normal title-btn"
                  to="/reports/profit-and-loss"
                  :disabled="
                    !allowedReports.includes('Profit and Lost') &&
                      !allowedReports.includes('All')
                  "
                >
                  <b-icon
                    class="text-main-green mr-1"
                    icon="chevron-right"
                    size="sm"
                    aria-label="Plus"
                    font-scale="2.5"
                  ></b-icon>
                </b-link>
              </b-row>
            </b-col>
          </b-row>
        </b-link>
        </b-card>
      </b-row>
      <!-- title row -->
      <b-row
        class="d-flex flex-row align-items-stretch justify-content-center w-100 px-3"
        no-gutters
        style="cursor:pointer;"
      >
        <b-card
          no-body
          class="position-relative overflow-hidden border-1 border-main-green report-card-row w-100"
        >
        <b-link
                  class="text-left font-primary font-weight-normal title-btn"
                  to="/reports/balance-sheet"
                  :disabled="
                    !allowedReports.includes('Balance Sheet') &&
                      !allowedReports.includes('All')
                  "
                >
          <b-row
            class="d-flex flex-row align-items-center justify-content-start my-3 my-md-2 px-4 w-100"
            no-gutters
          >
            <!-- title column  -->
            <b-col
              class="d-flex flex-column align-items-start justify-content-center"
              cols="11"
              md="8"
            >
              <b-row
                class="d-flex flex-row align-items-center justify-content-center"
                no-gutters
              >
              </b-row>
              <h4 class="pl-2 font-weight-bold">Balance Sheet</h4>
              <b-row
                class="d-flex flex-row align-items-center justify-content-start w-100"
                no-gutters
              >
                <span class="text-gray-750 pl-2">
                  Snapshot of what your business owns or is due to receive from
                  others (assets), what it owes to others (liabilities), and
                  what you've invested or retained in your company (equity).
                </span>
              </b-row>
            </b-col>
            <!-- icon column  -->
            <b-col
              class="d-flex flex-column align-items-end justify-content-center"
              cols="1"
              md="4"
            >
              <b-row
                class="d-flex flex-row align-items-center justify-content-center border-0 rounded-right"
                no-gutters
              >
                <b-icon
                  v-if="
                    !allowedReports.includes('Balance Sheet') &&
                      !allowedReports.includes('All')
                  "
                  icon="lock-fill"
                  class=""
                  scale="1.1"
                  aria-hidden="true"
                  variant="warning"
                  title="This feature is not available in your package"
                ></b-icon>
                <b-link
                  class="text-left font-primary font-weight-normal title-btn"
                  to="/reports/balance-sheet"
                  :disabled="
                    !allowedReports.includes('Balance Sheet') &&
                      !allowedReports.includes('All')
                  "
                >
                  <b-icon
                    class="text-main-green mr-1"
                    icon="chevron-right"
                    size="sm"
                    aria-label="Plus"
                    font-scale="2.5"
                  ></b-icon>
                </b-link>
              </b-row>
            </b-col>
          </b-row>
        </b-link>
        </b-card>
      </b-row>

      <!-- title row -->
      <b-row
        class="d-flex flex-row align-items-stretch justify-content-center w-100 px-3"
        no-gutters
        style="cursor: pointer;"
      >
        <b-card
          no-body
          class="position-relative overflow-hidden border-1 border-main-green report-card-row w-100"
        >
        <b-link
                  class="text-left font-primary font-weight-normal title-btn"
                  to="/reports/cash-flow"
                  :disabled="
                    !allowedReports.includes('Cash flow') &&
                      !allowedReports.includes('All')
                  "
                >
          <b-row
            class="d-flex flex-row align-items-center justify-content-start my-3 my-md-2 px-4 w-100"
            no-gutters
          >
            <!-- title column  -->
            <b-col
              class="d-flex flex-column align-items-start justify-content-center"
              cols="11"
              md="8"
            >
              <b-row
                class="d-flex flex-row align-items-center justify-content-center"
                no-gutters
              >
                <h4 class="pl-2 font-weight-bold">Cash Flow</h4>
              </b-row>
              <b-row
                class="d-flex flex-row align-items-center justify-content-start w-100"
                no-gutters
              >
                <span class="text-gray-750 pl-2">
                  Cash coming in and going out of your business. Includes items
                  not included in Profit & Loss such as repayment of loan
                  principal and owner drawings (paying yourself).
                </span>
              </b-row>
            </b-col>
            <!-- icon column  -->
            <b-col
              class="d-flex flex-column align-items-end justify-content-center"
              cols="1"
              md="4"
            >
              <b-row
                class="d-flex flex-row align-items-center justify-content-center border-0 rounded-right"
                no-gutters
              >
                <b-icon
                  v-if="
                    !allowedReports.includes('Cash flow') &&
                      !allowedReports.includes('All')
                  "
                  icon="lock-fill"
                  class=""
                  scale="1.1"
                  aria-hidden="true"
                  variant="warning"
                  title="This feature is not available in your package"
                ></b-icon>
                <b-link
                  class="text-left font-primary font-weight-normal title-btn"
                  to="/reports/cash-flow"
                  :disabled="
                    !allowedReports.includes('Cash flow') &&
                      !allowedReports.includes('All')
                  "
                >
                  <b-icon
                    class="text-main-green mr-1"
                    icon="chevron-right"
                    size="sm"
                    aria-label="Plus"
                    font-scale="2.5"
                  ></b-icon>
                </b-link>
              </b-row>
            </b-col>
          </b-row>
        </b-link>
        </b-card>
      </b-row>
      <!-- title row -->
      <b-row
        class="d-flex flex-row align-items-stretch justify-content-center w-100 px-3"
        no-gutters
        style="cursor: pointer;"
      >
        <b-card
          no-body
          class="position-relative overflow-hidden border-1 border-main-green report-card-row w-100"
        >
        <b-link
                  class="text-left font-primary font-weight-normal title-btn"
                  to="/reports/trial-balance"
                  :disabled="
                    !allowedReports.includes('Trial Balance') &&
                      !allowedReports.includes('All')
                  "
                >
          <b-row
            class="d-flex flex-row align-items-center justify-content-start my-3 my-md-2 px-4 w-100"
            no-gutters
          >
            <!-- title column  -->
            <b-col
              class="d-flex flex-column align-items-start justify-content-center"
              cols="11"
              md="8"
            >
              <b-row
                class="d-flex flex-row align-items-center justify-content-center"
                no-gutters
              >
             
                <h4 class="pl-2 font-weight-bold">Trial Balance</h4>
              </b-row>
              <b-row
                class="d-flex flex-row align-items-center justify-content-start w-100"
                no-gutters
              >
                <span class="text-gray-750 pl-2">
                  Balance of all your accounts on a specified date.
                </span>
              </b-row>
            </b-col>
            <!-- icon column  -->
            <b-col
              class="d-flex flex-column align-items-end justify-content-center"
              cols="1"
              md="4"
            >
              <b-row
                class="d-flex flex-row align-items-center justify-content-center border-0 rounded-right"
                no-gutters
              >
                <b-icon
                  v-if="
                    !allowedReports.includes('Trial Balance') &&
                      !allowedReports.includes('All')
                  "
                  icon="lock-fill"
                  class=""
                  scale="1.1"
                  aria-hidden="true"
                  variant="warning"
                  title="This feature is not available in your package"
                ></b-icon>
                <b-link
                  class="text-left font-primary font-weight-normal title-btn"
                  to="/reports/trial-balance"
                  :disabled="
                    !allowedReports.includes('Trial Balance') &&
                      !allowedReports.includes('All')
                  "
                >
                  <b-icon
                    class="text-main-green mr-1"
                    icon="chevron-right"
                    size="sm"
                    aria-label="Plus"
                    font-scale="2.5"
                  ></b-icon>
                </b-link>
              </b-row>
            </b-col>
          </b-row>
        </b-link>
        </b-card>
      </b-row>
      <!-- title row -->
      <b-row
        class="d-flex flex-row align-items-stretch justify-content-center w-100 px-3"
        no-gutters
        style="cursor: pointer;"
      >
        <b-card
          no-body
          class="position-relative overflow-hidden border-1 border-main-green report-card-row w-100"
        >
        <b-link
                  class="text-left font-primary font-weight-normal title-btn"
                  :to="!allowedReports.includes('General Ledger') && !allowedReports.includes('All') ? '/reports' : '/reports/general-ledger'"
                  :disabled="
                    !allowedReports.includes('General Ledger') &&
                      !allowedReports.includes('All')
                  "
                >
          <b-row
            class="d-flex flex-row align-items-center justify-content-start my-3 my-md-2 px-4 w-100"
            no-gutters
          >
            <!-- title column  -->
            <b-col
              class="d-flex flex-column align-items-start justify-content-center"
              cols="11"
              md="8"
            >
              <b-row
                class="d-flex flex-row align-items-center justify-content-center"
                no-gutters
              >
                  <h4 class="pl-2 font-weight-bold">General Ledger</h4>
              </b-row>
              <b-row
                class="d-flex flex-row align-items-center justify-content-start w-100"
                no-gutters
              >
                <span class="text-gray-750 pl-2">
                  Detailed list of all transactions and their total by
                  account—everything in your Chart of Accounts.
                </span>
              </b-row>
            </b-col>
            <!-- icon column  -->
            <b-col
              class="d-flex flex-column align-items-end justify-content-center"
              cols="1"
              md="4"
            >
              <b-row
                class="d-flex flex-row align-items-center justify-content-center border-0 rounded-right"
                no-gutters
              >
                <b-icon
                  v-if="
                    !allowedReports.includes('General Ledger') &&
                      !allowedReports.includes('All')
                  "
                  icon="lock-fill"
                  class=""
                  scale="1.1"
                  aria-hidden="true"
                  variant="warning"
                  title="This feature is not available in your package"
                ></b-icon>
                <b-link
                  class="text-left font-primary font-weight-normal title-btn"
                  :disabled="!allowedReports.includes('General Ledger') && !allowedReports.includes('All')"
                  :to="!allowedReports.includes('General Ledger') && !allowedReports.includes('All') ? '/reports' : '/reports/general-ledger'"
                >
                  <div>
                    <b-icon
                      class="text-main-green mr-1"
                      icon="chevron-right"
                      size="sm"
                      aria-label="Plus"
                      font-scale="2.5"
                    ></b-icon>
                  </div>
                </b-link>
              </b-row>
            </b-col>
          </b-row>
        </b-link>
        </b-card>
      </b-row>
      <!-- Finical Report -->
      <b-row
        class="d-flex flex-row align-items-stretch justify-content-center w-100 px-3 "
        no-gutters
        style="cursor: pointer;"
      >
     
        <b-card
          no-body
          class="position-relative overflow-hidden border-1 border-main-green report-card-row w-100 cursor-pointer "
        >
        <b-link
                  class="text-left font-primary font-weight-normal title-btn"
                  :to="!allowedReports.includes('Financial Report') && !allowedReports.includes('All') ? '/reports' : '/reports/financial-report'"
                  :disabled="!allowedReports.includes('Financial Report') && !allowedReports.includes('All')"
                >
          <b-row
            class="d-flex flex-row align-items-center justify-content-start my-3 my-md-2 px-4 w-100"
            no-gutters
          >
            <!-- title column  -->
            <b-col
              class="d-flex flex-column align-items-start justify-content-center"
              cols="11"
              md="8"
            >
              <b-row
                class="d-flex flex-row align-items-center justify-content-center"
                no-gutters
              >
                  <h4 class="pl-2 font-weight-bold">Financial Report</h4>
                
              </b-row>
              <b-row
                class="d-flex flex-row align-items-center justify-content-start w-100"
                no-gutters
              >
                <span class="text-gray-750 pl-2">
                  Detailed list of all financial transactions in your Chart of
                  Accounts.
                </span>
              </b-row>
            </b-col>
            <!-- icon column  -->
            <b-col
              class="d-flex flex-column align-items-end justify-content-center"
              cols="1"
              md="4"
            >
              <b-row
                class="d-flex flex-row align-items-center justify-content-center border-0 rounded-right"
                no-gutters
              >
                <b-icon
                    v-if="!allowedReports.includes('Financial Report') && !allowedReports.includes('All')"
                    icon="lock-fill"
                    class=""
                    scale="1.1"
                    aria-hidden="true"
                    variant="warning"
                    title="This feature is not available in your package"
                ></b-icon>
                
                <b-link
                  class="text-left font-primary font-weight-normal title-btn"
                  :disabled="!allowedReports.includes('Financial Report') && !allowedReports.includes('All')"
                  :to="!allowedReports.includes('Financial Report') && !allowedReports.includes('All') ? '/reports' : '/reports/financial-report'"
                >
                  <div>
                    <b-icon
                      class="text-main-green mr-1"
                      icon="chevron-right"
                      size="sm"
                      aria-label="Plus"
                      font-scale="2.5"
                      
                    ></b-icon>
                  </div>
                </b-link>

              </b-row>
            </b-col>
          </b-row>
        </b-link>
      </b-card>
      </b-row>
      <!-- GST Vat Report -->
      <b-row
        class="d-flex flex-row align-items-stretch justify-content-center w-100 px-3"
        no-gutters
        style="cursor: pointer;"
      >
        <b-card
          no-body
          class="position-relative overflow-hidden border-1 border-main-green report-card-row w-100"
        >
        <b-link
                  class="text-left font-primary font-weight-normal title-btn"
                  :to="!allowedReports.includes('GST VAT Report') && !allowedReports.includes('All') ? '/reports' : '/reports/gst-vat-report'"
                  :disabled="
                    !allowedReports.includes('GST VAT Report') &&
                      !allowedReports.includes('All')
                  "
                >
          <b-row
            class="d-flex flex-row align-items-center justify-content-start my-3 my-md-2 px-4 w-100"
            no-gutters
          >
            <!-- title column  -->
            <b-col
              class="d-flex flex-column align-items-start justify-content-center"
              cols="11"
              md="8"
            >
              <b-row
                class="d-flex flex-row align-items-center justify-content-center"
                no-gutters
              >
               
                  <h4 class="pl-2 font-weight-bold">GST VAT Report</h4>
              </b-row>
              <b-row
                class="d-flex flex-row align-items-center justify-content-start w-100"
                no-gutters
              >
                <span class="text-gray-750 pl-2">
                  Detailed list of all GST VAT in your Chart of Accounts.
                </span>
              </b-row>
            </b-col>
            <!-- icon column  -->
            <b-col
              class="d-flex flex-column align-items-end justify-content-center"
              cols="1"
              md="4"
            >
              <b-row
                class="d-flex flex-row align-items-center justify-content-center border-0 rounded-right"
                no-gutters
              >
              <b-icon
                  v-if="
                    !allowedReports.includes('GST VAT Report') &&
                      !allowedReports.includes('All')
                  "
                  icon="lock-fill"
                  class=""
                  scale="1.1"
                  aria-hidden="true"
                  variant="warning"
                  title="This feature is not available in your package"
                ></b-icon>
                <b-link
                  class="text-left font-primary font-weight-normal title-btn"
                  :to="!allowedReports.includes('GST VAT Report') && !allowedReports.includes('All') ? '/reports' : '/reports/gst-vat-report'"
                  :disabled="
                    !allowedReports.includes('GST VAT Report') &&
                      !allowedReports.includes('All')
                  "
                >
                  <b-icon
                    class="text-main-green mr-1"
                    icon="chevron-right"
                    size="sm"
                    aria-label="Plus"
                    font-scale="2.5"
                  ></b-icon>
                </b-link>
              </b-row>
            </b-col>
          </b-row>
        </b-link>
        </b-card>
      </b-row>
      <!--      &lt;!&ndash; title row &ndash;&gt;-->
      <!--      <b-row-->
      <!--        class="d-flex flex-row align-items-stretch justify-content-center w-100 px-3"-->
      <!--        no-gutters-->
      <!--      >-->
      <!--        <b-card-->
      <!--          no-body-->
      <!--          class="position-relative overflow-hidden border-1 border-main-green report-card-row w-100"-->
      <!--        >-->
      <!--          <b-row-->
      <!--            class="d-flex flex-row align-items-center justify-content-start my-3 my-md-2 px-4 w-100"-->
      <!--            no-gutters-->
      <!--          >-->
      <!--            &lt;!&ndash; title column  &ndash;&gt;-->
      <!--            <b-col-->
      <!--              class="d-flex flex-column align-items-start justify-content-center"-->
      <!--              cols="11"-->
      <!--              md="8"-->
      <!--            >-->
      <!--              <b-row-->
      <!--                class="d-flex flex-row align-items-center justify-content-center"-->
      <!--                no-gutters-->
      <!--              >-->
      <!--                <b-link-->
      <!--                  class="text-left font-primary font-weight-normal title-btn"-->
      <!--                  to="/reports/financial-report"-->
      <!--                >-->
      <!--                  <h4 class="pl-2 font-weight-bold">-->
      <!--                    Financial Report-->
      <!--                  </h4></b-link-->
      <!--                >-->
      <!--              </b-row>-->
      <!--              <b-row-->
      <!--                class="d-flex flex-row align-items-center justify-content-start w-100"-->
      <!--                no-gutters-->
      <!--              >-->
      <!--                <span class="text-gray-750 pl-2">-->
      <!--                  Detailed list of all financial transactions in your Chart of-->
      <!--                  Accounts.-->
      <!--                </span>-->
      <!--              </b-row>-->
      <!--            </b-col>-->
      <!--            &lt;!&ndash; icon column  &ndash;&gt;-->
      <!--            <b-col-->
      <!--              class="d-flex flex-column align-items-end justify-content-center"-->
      <!--              cols="1"-->
      <!--              md="4"-->
      <!--            >-->
      <!--              <b-row-->
      <!--                class="d-flex flex-row align-items-center justify-content-center border-0 rounded-right"-->
      <!--                no-gutters-->
      <!--              >-->
      <!--                <b-link-->
      <!--                  class="text-left font-primary font-weight-normal title-btn"-->
      <!--                  to="/reports/financial-report"-->
      <!--                >-->
      <!--                  <b-icon-->
      <!--                    class="text-main-green mr-1"-->
      <!--                    icon="chevron-right"-->
      <!--                    size="sm"-->
      <!--                    aria-label="Plus"-->
      <!--                    font-scale="2.5"-->
      <!--                  ></b-icon>-->
      <!--                </b-link>-->
      <!--              </b-row>-->
      <!--            </b-col>-->
      <!--          </b-row>-->
      <!--        </b-card>-->
      <!--      </b-row>-->
      <!--      &lt;!&ndash; title row &ndash;&gt;-->
      <!--      <b-row-->
      <!--        class="d-flex flex-row align-items-stretch justify-content-center w-100 px-3 mb-4"-->
      <!--        no-gutters-->
      <!--      >-->
      <!--        <b-card-->
      <!--          no-body-->
      <!--          class="position-relative overflow-hidden border-1 border-main-green report-card-row w-100"-->
      <!--        >-->
      <!--          <b-row-->
      <!--            class="d-flex flex-row align-items-center justify-content-start my-3 my-md-2 px-4 w-100"-->
      <!--            no-gutters-->
      <!--          >-->
      <!--            &lt;!&ndash; title column  &ndash;&gt;-->
      <!--            <b-col-->
      <!--              class="d-flex flex-column align-items-start justify-content-center"-->
      <!--              cols="11"-->
      <!--              md="8"-->
      <!--            >-->
      <!--              <b-row-->
      <!--                class="d-flex flex-row align-items-center justify-content-center"-->
      <!--                no-gutters-->
      <!--              >-->
      <!--                <b-link-->
      <!--                  class="text-left font-primary font-weight-normal title-btn"-->
      <!--                  to="/reports/vat-report"-->
      <!--                >-->
      <!--                  <h4 class="pl-2 font-weight-bold">GST VAT Report</h4></b-link-->
      <!--                >-->
      <!--              </b-row>-->
      <!--              <b-row-->
      <!--                class="d-flex flex-row align-items-center justify-content-start w-100"-->
      <!--                no-gutters-->
      <!--              >-->
      <!--                <span class="text-gray-750 pl-2">-->
      <!--                  Detailed list of all GST VAT in your Chart of Accounts.-->
      <!--                </span>-->
      <!--              </b-row>-->
      <!--            </b-col>-->
      <!--            &lt;!&ndash; icon column  &ndash;&gt;-->
      <!--            <b-col-->
      <!--              class="d-flex flex-column align-items-end justify-content-center"-->
      <!--              cols="1"-->
      <!--              md="4"-->
      <!--            >-->
      <!--              <b-row-->
      <!--                class="d-flex flex-row align-items-center justify-content-center border-0 rounded-right"-->
      <!--                no-gutters-->
      <!--              >-->
      <!--                <b-link-->
      <!--                  class="text-left font-primary font-weight-normal title-btn"-->
      <!--                  to="/reports/vat-report"-->
      <!--                >-->
      <!--                  <b-icon-->
      <!--                    class="text-main-green mr-1"-->
      <!--                    icon="chevron-right"-->
      <!--                    size="sm"-->
      <!--                    aria-label="Plus"-->
      <!--                    font-scale="2.5"-->
      <!--                  ></b-icon>-->
      <!--                </b-link>-->
      <!--              </b-row>-->
      <!--            </b-col>-->
      <!--          </b-row>-->
      <!--        </b-card>-->
      <!--      </b-row>-->
    </b-container>
  </HomeLayout>
</template>
<script>
// @ is an alias to /src
import HomeLayout from "@/layout/HomeLayout";
import { mapGetters } from "vuex";

// services

export default {
  name: "Reports",
  components: {
    HomeLayout,
  },
  data() {
    return {
      allowedReports: "",
    };
  },
  async mounted() {
    this.allowedReports = JSON.parse(this.getModulePermissions())[1].reports;
    console.log(this.allowedReports);
  },
  methods: {
    ...mapGetters({ getModulePermissions: "auth/getModulePermissions" }),
  },
};
</script>

<style scoped>
.root-container {
  min-height: 600px;
  width: 100%;
  background-color: white;
  border-radius: 5px;
}

.root-container >>> .report-card-row {
  /* padding: 25px; */
  padding: 20px;
  margin: 20px 40px 0px 40px;
  min-height: 120px;
  width: 100%;
  /* background-color: var(--white); */
  border-radius: 20px;
  border: 1px solid var(--main-green);
  /* margin-bottom: 20px; */
}
.root-container .title-btn {
  transition: color 200ms ease-in;
  color: var(--dark);
}
.root-container .title-btn:hover {
  color: var(--main-green);
  text-decoration: none;
}
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .root-container >>> .report-card-row {
    min-height: 200px;
    padding: 32px 5px;
    margin: 20px 20px 0px 20px;
    width: 100%;
  }
}
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .root-container >>> .report-card-row {
    min-height: 180px;
    padding: 20px 0px;
    margin: 5px;
    width: 100%;
  }
}
</style>
