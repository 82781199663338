<template>
  <HomeLayout>
    <b-container
      class="d-flex flex-column align-items-start justify-content-start px-0"
    >
      <!-- user insights row -->
      <b-row
        class="d-flex flex-row align-items-stretch justify-content-center w-100"
        no-gutters
      >
        <b-card
          no-body
          class="position-relative shadow-sm border-1 filter-cards-row w-100"
        >
          <b-row
            class="d-flex flex-row align-items-center justify-content-start px-3 w-100"
            no-gutters
          >
            <!-- Overdue column -->
            <b-col
              class="d-flex flex-column align-items-start justify-content-center px-0 py-2 py-lg-0"
              cols="6"
            >
              <h6 class="text-left text-truncate text-medium font-weight-bold">
                Filters
              </h6>
            </b-col>
            <!-- Upcoming payout column -->
            <b-col
              class="d-flex flex-column align-items-end justify-content-center px-0 py-2 py-lg-0"
              cols="6"
            >
              <b-dropdown
                variant="white"
                toggle-class="d-flex flex-row align-items-center justify-content-center mx-0 my-2 my-lg-0 text-main-green border border-main-green text-main-green rounded-lg rounded-pill export-btn"
                text="Export"
              >
                <b-dropdown-item @click="exportTrialBalanceFn"
                  >CSV</b-dropdown-item
                >
                <b-dropdown-item @click="exportPDFTrialBalanceFn"
                  >PDF</b-dropdown-item
                >
              </b-dropdown>
            </b-col>
          </b-row>
          <!-- date filters row  -->
          <b-row
            class="d-flex flex-row align-items-start align-items-md-center justify-content-between mt-2 mt-sm-4 px-3 date-filter-row"
            no-gutters
          >
            <b-col
              cols="12"
              sm="2"
              md="1"
              class="mb-2 mb-md-0 align-self-center"
            >
              <span class="text-left text-dark font-primary font-weight-normal"
                >As
              </span>
            </b-col>
            <b-col
              cols="12"
              sm="5"
              md="2"
              class="mb-2 mb-md-0 align-self-center mt-3"
            >
              <FormSelect
                groupId="dateRange-group"
                id="dateRange-group-input"
                class="text-prime-gray rounded-2 report-select align-self-center"
                v-model="dateRange"
                :whiteBG="false"
                :options="dateRangeOptions"
                form="login-form"
              ></FormSelect>
            </b-col>
            <b-col
              cols="12"
              sm="9"
              lg="5"
              class="w-100 d-flex flex-column flex-md-row align-self-center"
            >
              <b-col
                cols="12"
                md="6"
                lg="7"
                class="mb-3 mb-md-0 px-0 align-self-center"
              >
                <b-input-group>
                  <b-form-input
                    id="example-input"
                    :value="formatDate(startDate)"
                    type="text"
                    class="border-main-green date-picker"
                    placeholder="Choose a date"
                    autocomplete="off"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      class="date-picker-btn font-primary datepicker align-items-right"
                      v-model="startDate"
                      no-flip
                      button-only
                      button-variant="white"
                      locale="en-US"
                      right
                      :state="startDateError"
                      nav-button-variant="main-green"
                      today-variant="main-green"
                      menu-class=" rounded-lg custom-calendar-dropdown text-prime-gray"
                      aria-controls="start-date-input"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <!-- <b-col cols="12" md="6" lg="8" class="pl-0 pl-md-1">
                <b-input-group>
                  <b-form-input
                    id="example-input"
                    v-model="endDate"
                    class="border-info date-picker"
                    type="text"
                    placeholder="To"
                    autocomplete="off"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      class="date-picker-btn font-primary datepicker align-items-center "
                      v-model="endDate"
                      :min="minEndDate"
                      no-flip
                      button-only
                      button-variant="white"
                      locale="en-US"
                      right
                      :state="endDateError"
                      nav-button-variant="info"
                      today-variant="info"
                      menu-class=" rounded-lg custom-calendar-dropdown text-prime-gray"
                      aria-controls="start-date-input"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
              </b-col> -->
            </b-col>
            <b-col cols="0" lg="2"> </b-col>
          </b-row>
          <!-- full row input (No :) -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-start w-100 px-3"
            no-gutters
          >
            <b-col
              class="d-flex flex-row align-items-center justify-content-end py-1"
            >
              <b-link
                class="d-flex flex-column align-items-end justify-content-center bg-white border-0 text-left font-primary text-main-green font-weight-bold load-more-btn"
                pill
                @click="clearFilterFn"
                v-if="computedFilterValidations"
              >
                <span class="d-inline mx-1 py-1 mt-3">Clear all</span>
              </b-link>
            </b-col>
            <b-col
              class="d-flex flex-row align-items-end justify-content-end mb-3 mb-md-4 py-1"
            >
              <b-button
                variant="main-green"
                class="d-flex flex-column align-items-end justify-content-end border-0 action-btn"
                pill
                @click="updateReport"
              >
                <span class="d-inline mx-1 py-1">Update Report</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-row>
      <!-- table container row -->
      <b-row
        class="d-flex flex-row align-items-stretch justify-content-center w-100 px-1 py-0 py-lg-1 mt-0 mt-lg-3"
        no-gutters
      >
        <b-card
          no-body
          class="shadow-sm border-0 table-container-card px-3 py-3 w-100"
        >
          <!-- header row  -->
          <b-row
            class="d-flex flex-row align-items-center justify-content-start bg-main-green py-3 px-3 w-100"
            no-gutters
          >
            <!-- Accounts column -->
            <b-col
              class="d-flex flex-column align-items-start justify-content-center px-0 py-2 py-lg-0"
              cols="6"
            >
              <h6
                class="text-left text-uppercase font-weight-normal text-white"
              >
                Accounts
              </h6>
            </b-col>
            <!-- debit column -->
            <b-col
              class="d-flex flex-column align-items-end justify-content-center px-0 py-2 py-lg-0"
              cols="3"
            >
              <h6
                class="text-left text-uppercase font-weight-normal text-white"
              >
                Debit
              </h6>
            </b-col>
            <!-- credit column -->
            <b-col
              class="d-flex flex-column align-items-end justify-content-center px-0 py-2 py-lg-0"
              cols="3"
            >
              <h6
                class="text-left text-uppercase font-weight-normal text-white"
              >
                Credit
              </h6>
            </b-col>
          </b-row>

          <!-- <h4>Assets</h4>
          <div v-for="account in trialBalanceData.categories.assets" :key="account">
            <h5>{{ account }}</h5>
          </div> -->

          <!-- detail row  -->
          <b-overlay :show="isLoading" z-index="4" no-wrap></b-overlay>
          <b-row
            v-for="account in trialBalanceData.categories"
            :key="account.title"
            class="d-flex flex-row align-items-center justify-content-start py-1 w-100"
            no-gutters
          >
            <b-row class="bg-light py-3 px-3 w-100" no-gutters>
              <!-- Accounts column -->
              <b-col
                class="d-flex flex-column align-items-start justify-content-center px-0 py-2 py-lg-0"
                cols="6"
              >
                <h6 class="text-left font-weight-bold text-dark">
                  {{ account.title }}
                </h6>
              </b-col>
            </b-row>
            <!-- detail row  -->
            <b-row
              class="py-1 my-2 px-3 w-100"
              no-gutters
              v-for="category in account.accounts"
              :key="category.title"
            >
              <!-- Accounts column -->
              <b-col
                class="d-flex flex-column align-items-start justify-content-center px-0 py-2 py-lg-0"
                cols="6"
              >
                <h6 class="text-left font-weight-normal text-dark">
                  {{ category.title }}
                </h6>
              </b-col>
              <!-- date column -->
              <b-col
                class="d-flex flex-column align-items-end justify-content-center px-0 py-2 py-lg-0"
                cols="3"
              >
                <h6 class="text-left text-normal font-weight-normal text-dark">
                  {{ currency }} {{ getFormattedNumbers(category.income) }}
                </h6>
              </b-col>
              <!-- date column -->
              <b-col
                class="d-flex flex-column align-items-end justify-content-center px-0 py-2 py-lg-0"
                cols="3"
              >
                <h6 class="text-left text-normal font-weight-normal text-dark">
                  {{ currency }} {{ getFormattedNumbers(category.expense) }}
                </h6>
              </b-col>
            </b-row>
            <!-- sub total row  -->
            <b-row class="py-3 my-2 px-3 w-100" no-gutters>
              <!-- Accounts column -->
              <b-col
                class="d-flex flex-column align-items-start justify-content-center px-0 py-2 py-lg-0"
                cols="6"
              >
                <h6 class="text-left font-weight-bold text-dark">
                  Total {{ account.title }}
                </h6>
              </b-col>
              <!-- total debit column -->
              <b-col
                class="d-flex flex-column align-items-end justify-content-center px-0 py-2 py-lg-0"
                cols="3"
              >
                <h6 class="text-left text-normal font-weight-normal text-dark">
                  {{ currency }}
                  {{
                    account.totalIncome
                      ? getFormattedNumbers(account.totalIncome)
                      : "0.00"
                  }}
                </h6>
              </b-col>
              <!-- total credit column -->
              <b-col
                class="d-flex flex-column align-items-end justify-content-center px-0 py-2 py-lg-0"
                cols="3"
              >
                <h6 class="text-left text-normal font-weight-normal text-dark">
                  {{ currency }}
                  {{
                    account.totalExpense
                      ? getFormattedNumbers(account.totalExpense)
                      : "0.00"
                  }}
                </h6>
              </b-col>
            </b-row>
          </b-row>
          <!-- Total for all  accounts -->
          <b-row class="py-3 my-2 px-3 w-100 bg-gray-450" no-gutters>
            <!-- Accounts column -->
            <b-col
              class="d-flex flex-column align-items-start justify-content-center px-0 py-2 py-lg-0"
              cols="6"
            >
              <h6 class="text-left font-weight-bold text-dark">
                Total for all accounts
              </h6>
            </b-col>
            <!-- total debit column -->
            <b-col
              class="d-flex flex-column align-items-end justify-content-center px-0 py-2 py-lg-0"
              cols="3"
            >
              <h6 class="text-left text-normal font-weight-bold text-dark">
                {{ currency }}
                {{
                  trialBalanceData.totalIncome
                    ? getFormattedNumbers(trialBalanceData.totalIncome)
                    : "0.00"
                }}
              </h6>
            </b-col>
            <!-- total credit column -->
            <b-col
              class="d-flex flex-column align-items-end justify-content-center px-0 py-2 py-lg-0"
              cols="3"
            >
              <h6 class="text-left text-normal font-weight-bold text-dark">
                {{ currency }}
                {{
                  trialBalanceData.totalExpense
                    ? getFormattedNumbers(trialBalanceData.totalExpense)
                    : "0.00"
                }}
              </h6>
            </b-col>
          </b-row>
        </b-card>
      </b-row>
    </b-container>
  </HomeLayout>
</template>
<script>
//utils
import { formatNumbers } from "@/util/formatNumbers.js";

// @ is an alias to /src
import { baseURL } from "@/services/config";
import HomeLayout from "@/layout/HomeLayout";
import FormSelect from "@/components/Form/FormSelect";

// services
import {
  GetTrialBalance,
  GetTrialBalanceFilters,
  ExportTrialBalance,
  ExportPDFTrialBalance,
} from "@/services/reports.service";
import { GetCurrentWorkspace } from "@/services/settingsbusiness.service";
import { FiscalYearRange } from "@/services/analytics.service";
export default {
  name: "TrialBalance",
  components: {
    HomeLayout,
    FormSelect,
  },
  data() {
    return {
      currency: localStorage.getItem("CURRENCY"),
      isLoading: false,
      startDate: null,
      endDate: null,
      startDateError: null,
      endDateError: null,
      reportType: null,
      isFiltersApplied: false,
      currentYear: new Date(Date.now()).getFullYear(),
      dateRange: null,
      // dateRangeOptions: ["This financial year","Last financial year","Last year Q1","Last year Q2","Last year Q3","Last year Q4"],
      dateRangeOptions: null,
      reportTypeOptions: [
        {
          text: "Accrual (Paid & Unpaid)",
          value: "accrual",
        },
        {
          text: "Cash Basis (Paid)",
          value: "cash",
        },
      ],
      // reportTypeOptions: ["Accrual (Paid & Unpaid)", "Cash Basis (Paid)"],
      // trialBalanceData: {
      //   netAmount: 0,
      //   totalIncome: 2014197.5,
      //   totalExpense: 2014197.5,
      //   categories: {
      //     assets: {
      //       title: "assets",
      //       totalIncome: 2014197.5,
      //       totalExpense: 0,
      //       accounts: {
      //         "Cash And Bank": {
      //           title: "Cash And Bank",
      //           income: 2014197.5,
      //           expense: 0,
      //         },
      //       },
      //     },
      //     liability: {
      //       title: "liability",
      //       totalIncome: 0,
      //       totalExpense: 0,
      //     },
      //     income: {
      //       title: "income",
      //       totalIncome: 0,
      //       totalExpense: 2014197.5,
      //       accounts: {
      //         "Uncategorized Income": {
      //           title: "Uncategorized Income",
      //           income: 0,
      //           expense: 2014197.5,
      //         },
      //       },
      //     },
      //     expenses: {
      //       title: "expenses",
      //       totalIncome: 0,
      //       totalExpense: 0,
      //     },
      //     equity: {
      //       title: "equity",
      //       totalIncome: 0,
      //       totalExpense: 0,
      //     },
      //   },
      // },
      // trialBalanceData: {
      //   title: "Total for all accounts",
      //   totalDebitAmount: "LKR 6,000.00",
      //   totalCreditAmount: "LKR 300.00",
      //   assets: {
      //     title: "Assets",
      //     totalDebitAmount: "LKR 3,000.00",
      //     totalCreditAmount: "LKR 300.00",
      //     categories: [
      //       {
      //         title: "Accounts Recievable",
      //         debitAmount: "LKR 2,000.00",
      //         creditAmount: "LKR 100.00",
      //       },
      //       {
      //         title: "Cash on Hand",
      //         debitAmount: "LKR 1,000.00",
      //         creditAmount: "LKR 200.00",
      //       },
      //     ],
      //   },
      //   liabilities: {
      //     title: "Liabilities",
      //     totalDebitAmount: "LKR 1,000.00",
      //     totalCreditAmount: "LKR 0.00",
      //     categories: [
      //       {
      //         title: "Unrealized Loss on Foreign Exchange",
      //         debitAmount: "LKR 1,000.00",
      //         creditAmount: "LKR 0.00",
      //       },
      //     ],
      //   },
      //   equity: {
      //     title: "Equity",
      //     totalDebitAmount: "LKR 1,000.00",
      //     totalCreditAmount: "LKR 0.00",
      //     categories: [
      //       {
      //         title: "Profit for all prior years",
      //         debitAmount: "LKR 1,000.00",
      //         creditAmount: "LKR 0.00",
      //       },
      //     ],
      //   },
      //   income: {
      //     title: "Income",
      //     totalDebitAmount: "LKR 1,000.00",
      //     totalCreditAmount: "LKR 0.00",
      //     categories: [
      //       {
      //         title: "Sales",
      //         debitAmount: "LKR 1,000.00",
      //         creditAmount: "LKR 0.00",
      //       },
      //     ],
      //   },
      //   expense: {
      //     title: "Expense",
      //     totalDebitAmount: "LKR 1,000.00",
      //     totalCreditAmount: "LKR 0.00",
      //     categories: [
      //       {
      //         title: "Unrealized Loss on Foreign Exchange",
      //         debitAmount: "LKR 1,000.00",
      //         creditAmount: "LKR 0.00",
      //       },
      //     ],
      //   },
      // },
      trialBalanceData: {},
    };
  },
  async mounted() {
    window.scrollTo(0, 0);
    await this.loadCurrentWorkspace();
    await this.initFn();
    const {
      data: { fiscal_years },
    } = await FiscalYearRange();
    this.dateRangeOptions = fiscal_years;
    const currentYear = new Date().getFullYear();
    // const startYear = currentYear - 20;
    // const endYear = currentYear + 20;
    // for (let i = startYear; i <= endYear; i++) {
    //   this.dateRangeOptions.push(i);
    // }
    this.dateRange = currentYear.toString();
  },
  watch: {
    startDate() {
      // reset the start date when end date changes
      this.endDate = "";
    },
    dateRange(val) {
      this.startDate = `${val}-12-31`;
    },
  },
  computed: {
    minEndDate() {
      return this.startDate;
    },
    computedFilterValidations() {
      const selectYear = Number(this.startDate.split("-")[0]);
      if (this.reportType || !(selectYear === this.currentYear)) {
        return true;
      }
      if (this.isFiltersApplied) this.clearFilterFn();
      return false;
    },
  },
  methods: {
    async loadCurrentWorkspace() {
      let { data } = await GetCurrentWorkspace();
      this.currency = data.currency;
    },
    async initFn() {
      try {
        this.isLoading = true;
        let { data } = await GetTrialBalance();
        console.log("trial balance", data);
        this.trialBalanceData = data;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    async updateReport() {
      if (this.startDate || this.endDate || this.reportType) {
        this.startDateError = true;
        this.endDateError = true;
        let payload = {
          endDate: this.startDate,
          report_type: this.reportType,
        };
        console.log(payload);
        try {
          this.isLoading = true;
          let { data } = await GetTrialBalanceFilters(payload);
          this.trialBalanceData = data;
          console.log("balance Sheet filter", data);
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          console.log(error);
        }
      } else {
        this.startDateError = false;
        this.endDateError = false;
      }
    },
    async exportTrialBalanceFn() {
      let payload = {
        endDate: this.startDate,
        reportType: this.reportType,
      };
      try {
        let { data, status } = await ExportTrialBalance(payload);
        console.log("export ", data);
        if (status == 200) {
          console.log(baseURL);
          window.open(`${baseURL}/reports/download/${data}`);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async exportPDFTrialBalanceFn() {
      let payload = {
        endDate: this.startDate,
        reportType: this.reportType,
      };
      try {
        let { data, status } = await ExportPDFTrialBalance(payload);
        console.log("export ", data);
        if (status == 200) {
          console.log(baseURL);
          window.open(`${baseURL}/reports/download/${data}`);
        }
      } catch (error) {
        console.log(error);
      }
    },
    getFormattedNumbers(text) {
      if (!text) return "0.00";
      return formatNumbers(text);
    },
    clearFilterFn() {
      this.initFn();
      this.startDate = `${this.currentYear}-12-31`;
      this.endDate = undefined;
      this.reportType = undefined;
      this.isFiltersApplied = false;
      this.dateRange = new Date().getFullYear();
    },
    formatDate(val) {
      if (val) {
        const dates = val.split("-");
        return `${dates[2]}/${dates[1]}/${dates[0]}`;
      }
    },
  },
};
</script>

<style scoped>
/* insights card */
.export-btn {
  width: 160px;
  height: 50px;
  border: 1.8px solid #0b9b7d;
}

.date-picker-btn >>> .btn .b-icon.bi {
  color: var(--main-green);
}

.b-input-group {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.b-input-group-append {
  margin-left: -1px; /* adjust as needed */
}

.date-picker-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: 1px solid var(--main-green);
  height: 38px;
}

.b-form-datepicker {
  margin-left: -1px; /* adjust as needed */
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.b-form-input {
  border-radius: 0 !important;
}

.report-type {
  margin-left: -10%;
}

.filter-cards-row {
  padding: 25px;
  height: 240px;
  width: 100%;
  background-color: var(--white);
  border-radius: 5px;
  border: 1px solid var(--main-green);
  margin-bottom: 30px;
}
.filter-cards-row .date-filter-row {
  /* width: 75%; */
}
.action-btn:hover {
  background: #0b9b7d;
  box-shadow: inset 0px 0px 10px #ffffff;
  border: none;
}
/* table card */
.table-card-row {
  height: 100%;
}
.table-card-row .table-container-card {
  background-color: var(--white);
  width: 100%;
  min-height: 560px;
  border-radius: 5px;
  border: 1px solid var(--light);
}
.table-card-row .table-container-card >>> .tab-wrapper {
  /* border-bottom: 0.5px solid var(--gray-750); */
  border-bottom: none;
}
.table-card-row .table-container-card >>> .invoice-tabs {
  width: 100%;
  font-size: 1rem;
  overflow: hidden;
  /* background-color: var(--ghost-white); */
}
.table-card-row .table-container-card >>> .invoice-tabs .tab-nav-link {
  font-size: 0.9rem;
  padding-right: 20px;
  white-space: nowrap;
  background: transparent;
  border: transparent;
  color: var(--gray-750);
  /* border-bottom: 1px solid var(--prime-gray); */
}
.table-card-row .table-container-card >>> .invoice-tabs .tab-nav-link:hover {
  background-color: var(--light);
  color: #0b9b7d;
}
.table-card-row .table-container-card >>> .invoice-tabs .tab-nav-link.active {
  background-color: var(--light);
  border-bottom: 2px solid #0b9b7d;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .filter-cards-row {
    min-height: 270px;
    padding: 20px 5px;
    margin: 20px 5px;
    width: 100%;
  }
  .filter-cards-row .date-filter-row {
    width: 100%;
  }
}
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .filter-cards-row {
    min-height: 360px;
    padding: 20px 0px;
    margin: 5px;
    width: 100%;
  }
  .filter-cards-row .date-filter-row {
    width: 100%;
  }

  .report-type {
    margin-left: 0%;
  }
}
</style>
