<template>
  <b-row
    class="
      d-flex
      flex-row
      align-items-start
      justify-content-start
      px-3
      w-100
      table-container-row
    "
    no-gutters
  >
    <!-- header row  -->
    <b-row
      class="
        d-flex
        flex-row
        align-items-center
        justify-content-start
        bg-main-green
        py-3
        px-3
        w-100
      "
      no-gutters
    >
      <!-- Accounts column -->
      <b-col
        class="
          d-flex
          flex-column
          align-items-start
          justify-content-center
          px-0
          py-2 py-lg-0
        "
        cols="6"
      >
        <h6 class="text-left text-uppercase font-weight-normal text-white">
          Accounts
        </h6>
      </b-col>
      <!-- date column -->
      <b-col
        class="
          d-flex
          flex-column
          align-items-end
          justify-content-center
          px-0
          py-2 py-lg-0
        "
        cols="6"
      >
        <h6
          class="text-left text-normal font-weight-normal text-white"
          v-if="startDate && endDate"
        >
          {{ startDate }} to {{ endDate }}
          <!-- Jan 01, 2021 to Jun 09, 2021 -->
        </h6>
      </b-col>
    </b-row>
    <!-- detail row  -->
    <b-row
      v-for="account in profitLossData"
      :key="account.title"
      class="
        d-flex
        flex-row
        align-items-center
        justify-content-start
        py-1
        w-100
      "
      no-gutters
    >
      <b-row class="bg-light py-3 px-3 w-100" no-gutters>
        <!-- Accounts column -->
        <b-col
          class="
            d-flex
            flex-column
            align-items-start
            justify-content-center
            px-0
            py-2 py-lg-0
          "
          cols="6"
        >
          <h6 class="text-left font-weight-bold text-dark">
            {{ account.title }}
          </h6>
        </b-col>
        <!-- date column -->
        <b-col
          class="
            d-flex
            flex-column
            align-items-end
            justify-content-center
            px-0
            py-2 py-lg-0
          "
          cols="6"
        >
          <h6 class="text-left text-normal font-weight-normal text-dark">
            {{ currency }} {{ getFormattedNumbers(account.total) }}
          </h6>
        </b-col>

        <template v-if="account.title == 'Gross Profit'">
          <b-col
            class="
              d-flex
              flex-column
              align-items-start
              justify-content-center
              px-0
            "
            cols="6"
          >
            <span class="text-left text-gray-600 text-small">
              As a percentage of Total Income
            </span>
          </b-col>
          <b-col
            class="
              d-flex
              flex-column
              align-items-end
              justify-content-center
              px-0
            "
            cols="6"
          >
            <span
              v-if="profitLossData.income.total.toFixed(2) == 0.0"
              class="text-left text-gray-600 text-small"
            >
              {{ getFormattedNumbers(account.total) }} %
            </span>
            <span v-else class="text-left text-gray-600 text-small">
              <!-- {{((account.total.toFixed(2) / profitLossData.income.total.toFixed(2)) * 100).toFixed(2)}} % -->
              {{
                getFormattedNumbers(
                  (account.total / profitLossData.income.total) * 100
                )
              }}
              %
            </span>
          </b-col>
        </template>
        <template v-if="account.title == 'Net Profit'">
          <b-col
            class="
              d-flex
              flex-column
              align-items-start
              justify-content-center
              px-0
            "
            cols="6"
          >
            <span class="text-left text-gray-600 text-small">
              As a percentage of Total Income
            </span>
          </b-col>
          <b-col
            class="
              d-flex
              flex-column
              align-items-end
              justify-content-center
              px-0
            "
            cols="6"
          >
            <span
              v-if="profitLossData.income.total.toFixed(2) == 0.0"
              class="text-left text-gray-600 text-small"
            >
              {{ getFormattedNumbers(account.total) }} %
            </span>
            <span v-else class="text-left text-gray-600 text-small">
              <!-- {{((account.total.toFixed(2) / profitLossData.income.total.toFixed(2)) * 100).toFixed(2)}} % -->
              {{
                getFormattedNumbers(
                  (account.total / profitLossData.income.total) * 100
                )
              }}
              %
            </span>
          </b-col>
        </template>
      </b-row>
      <!-- detail row  -->
      <b-row
        class="py-1 my-2 px-3 w-100"
        no-gutters
        v-for="category in account.categories"
        :key="category.title"
      >
        <!-- Accounts column -->
        <b-col
          class="
            d-flex
            flex-column
            align-items-start
            justify-content-center
            px-0
            py-2 py-lg-0
          "
          cols="6"
        >
          <h6 class="text-left font-weight-normal text-dark">
            {{ category.title }}
          </h6>
        </b-col>
        <!-- date column -->
        <b-col
          class="
            d-flex
            flex-column
            align-items-end
            justify-content-center
            px-0
            py-2 py-lg-0
          "
          cols="6"
        >
          <h6 class="text-left text-normal font-weight-normal text-dark">
            {{ currency }} {{ getFormattedNumbers(category.total) }}
          </h6>
        </b-col>
      </b-row>
      <!-- total row  -->
      <b-row class="py-3 my-2 px-3 w-100" no-gutters>
        <!-- Accounts column -->
        <b-col
          class="
            d-flex
            flex-column
            align-items-start
            justify-content-center
            px-0
            py-2 py-lg-0
          "
          cols="6"
        >
          <h6 class="text-left font-weight-bold text-dark">
            Total {{ account.title }}
          </h6>
        </b-col>
        <!-- date column -->
        <b-col
          class="
            d-flex
            flex-column
            align-items-end
            justify-content-center
            px-0
            py-2 py-lg-0
          "
          cols="6"
        >
          <h6 class="text-left text-normal font-weight-normal text-dark">
            {{ currency }} {{ getFormattedNumbers(account.total) }}
          </h6>
        </b-col>
      </b-row>
    </b-row>
  </b-row>
</template>

<script>
// utils
import { formatNumbers } from "@/util/formatNumbers.js";
import {GetCurrentWorkspace} from "@/services/settingsbusiness.service";

export default {
  props: {
    profitLossData: {
      type: Object,
    },
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
  },
  name: "ProfitLossDetails",
  components: {},
  data() {
    return {
      temp: {
        income: {
          title: "Income",
          total: this.currency+" 3,000.00",
          categories: [
            {
              title: "Sales",
              total: this.currency+" 1,000.00",
            },
          ],
        },
        costOfGoods: {
          title: "Cost of Goods Sold",
          total: this.currency+" 1,000.00",
        },
        grossProfit: {
          title: "Gross Profit",
          total: this.currency+" 1,000.00",
          percentage: "100.00%",
        },
        operatingExpenses: {
          title: "Operating Expenses",
          total: this.currency+" 1,000.00",
          categories: [
            {
              title: "Unrealized Loss on Foreign Exchange",
              total: this.currency+" 1,000.00",
            },
          ],
        },
        netProfit: {
          title: "Nett Profit",
          total: this.currency+" 1,000.00",
          percentage: "98.80%",
        },
      },
      currency:localStorage.getItem('CURRENCY')
    };
  },
  async mounted() {
    // await this.loadCurrentWorkspace();
  },
  methods: {
    async loadCurrentWorkspace() {
      let {data} = await GetCurrentWorkspace();
      this.currency = data.currency;
    },
    getFormattedNumbers(text) {
      if (!text) return "0.00";
      return formatNumbers(text);
    },
  },
};
</script>

<style scoped>
/* table container */
.table-container-row {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}

/* table question action buttons */
/* edit icon button (default state) */
.table-container-row .edit-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}

.table-container-row .edit-btn > .edit-icon {
  color: var(--main-green);
  transition: color 200ms ease-in;
}

/* edit icon button (hover state) */
.table-container-row .edit-btn:hover {
  background-color: var(--main-green);
}

.table-container-row .edit-btn:hover > .edit-icon {
  color: var(--white);
}

/* delete icon button (default state) */
.table-container-row .delete-btn {
  width: 40px;
  height: 40px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}

.table-container-row .delete-btn > .delete-icon {
  color: var(--danger);
  transition: color 200ms ease-in;
}

/* delete icon button (hover state) */
.table-container-row .delete-btn:hover {
  background-color: var(--danger);
}

.table-container-row .delete-btn:hover > .delete-icon {
  color: var(--white);
}

/* scrollbar size fix for webkit browsers (chrome/safari) */
.table-container-row::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}

.table-container-row::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}

.table-container-row::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .table-card-row .filter-dropdown,
  .table-card-row .export-dropdown {
    min-width: auto;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  /* .insight-cards-row {
    height: auto;
  } */
}
</style>
