<template>
  <HomeLayout>
    <b-container
      class="d-flex flex-column align-items-start justify-content-start px-0"
    >
      <!-- user insights row -->

      <b-row
        class="d-flex flex-row align-items-stretch justify-content-center w-100"
        no-gutters
      >
        <b-card
          no-body
          class="position-relative shadow-sm border-1 filter-cards-row w-100"
        >
          <b-row
            class="d-flex flex-row align-items-center justify-content-start px-3 w-100"
            no-gutters
          >
            <!-- Overdue column -->

            <b-col
              class="d-flex flex-column align-items-start justify-content-center px-0 py-2 py-lg-0"
              cols="6"
            >
              <h6
                class="text-left text-truncate text-medium font-weight-bold"
              >
                Filters
              </h6>
            </b-col>

            <!-- Upcoming payout column -->

            <b-col
              class="d-flex flex-column align-items-end justify-content-center px-0 py-2 py-lg-0"
              cols="6"
            >
              <b-dropdown
                variant="white"
                toggle-class="d-flex flex-row align-items-center justify-content-center mx-0 my-2 my-lg-0 text-main-green border border-main-green text-main-green rounded-lg rounded-pill export-btn"
                text="Export"
              >
                <b-dropdown-item @click="exportGeneralLedgerFn"
                  >CSV</b-dropdown-item
                >
                <b-dropdown-item @click="exportPDFGeneralLedgerFn"
                  >PDF</b-dropdown-item
                >
              </b-dropdown>
            </b-col>
          </b-row>

          <!-- date filters row  -->

          <b-row
            class="d-flex flex-row align-items-start justify-content-start pt-3 px-3 date-filter-row"
            no-gutters
          >
            <b-col
              cols="1"
              lg="1"
              class="d-flex flex-column align-items-start justify-content-center"
            >
              <span class="text-left text-dark font-primary font-weight-normal"
                >Account
              </span>
            </b-col>

            <b-col cols="3" lg="3" class="pr-0 pr-lg-4">
              <FormSelect
                groupId="fiscalyear-group"
                id="fiscalyear-group-input"
                class="text-prime-gray rounded-2 report-select"
                v-model="accountType"
                :whiteBG="false"
                :options="accountOptions"
                form="login-form"
              ></FormSelect>
            </b-col>

            <b-col cols="2" lg="2" class="pl-0 pl-lg-2">
              <span class="text-center text-dark font-primary font-weight-normal"
                >Date Range
              </span>
            </b-col>

            <b-col
              cols="2"
              lg="2"
              class="mb-2 mb-md-0"
            >
              <FormSelect
                groupId="dateRange-group"
                id="dateRange-group-input"
                class="text-prime-gray rounded-2 report-select align-self-center"
                v-model="date_range"
                :whiteBG="false"
                :options="dateRangeOptions"
                form="login-form"
              ></FormSelect>
            </b-col>

            <b-col cols="4" lg="4" class="w-100 d-flex flex-column flex-md-row">
              <b-col cols="12" md="6" lg="6" class="mb-3 mb-md-0 px-0 px-md-1">
                <b-input-group>
                  <b-form-input
                    id="example-input"
                    :value="formatDate(startDate)"
                    type="text"
                    class="border-main-green date-picker"
                    placeholder="From"
                    autocomplete="off"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      class="date-picker-btn font-primary datepicker align-items-right"
                      v-model="startDate"
                      no-flip
                      button-only
                      button-variant="white"
                      locale="en-US"
                      right
                      :state="startDateError"
                      nav-button-variant="main-green"
                      today-variant="main-green"
                      menu-class=" rounded-lg custom-calendar-dropdown text-prime-gray"
                      aria-controls="start-date-input"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <b-col cols="12" md="6" lg="6" class="pl-0 pl-md-1">
                <b-input-group>
                  <b-form-input
                    id="example-input"
                    :value="formatDate(endDate)"
                    class="border-main-green date-picker"
                    type="text"
                    placeholder="To"
                    autocomplete="off"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      class="date-picker-btn font-primary datepicker align-items-right "
                      v-model="endDate"
                      :min="minEndDate"
                      no-flip
                      button-only
                      button-variant="white"
                      locale="en-US"
                      right
                      :state="endDateError"
                      nav-button-variant="main-green"
                      today-variant="main-green"
                      menu-class=" rounded-lg custom-calendar-dropdown text-prime-gray"
                      aria-controls="start-date-input"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-col>
          </b-row>

          <!-- full row input (No :) -->

          <b-row
            class="d-flex flex-row align-items-start justify-content-start w-100 pt-3 px-3"
            no-gutters
          >
           

            <b-col cols="3" lg="2" >
              <span class="d-flex flex-row align-items-start justify-content-start text-dark font-primary font-weight-normal"
                >Customers
              </span>
            </b-col>

            <b-col d-flex align-items-start cols="9" lg="2">
              <FormSelect
                groupId="fiscalyear-group"
                id="fiscalyear-group-input"
                class="text-prime-gray rounded-2 report-select"
                v-model="selectedCustomer"
                :whiteBG="false"
                :options="customersList"
                form="login-form"
              ></FormSelect>
            </b-col>

            <b-col
              lg="2"
              md="6"
              class="d-flex flex-row align-items-center justify-content-end py-1"
            >
              <b-link
                class="d-flex flex-column align-items-end justify-content-center bg-white border-0 text-left font-primary text-main-green font-weight-bold load-more-btn"
                pill
                @click="clearFilterFn"
                v-if="computedFilterValidations"
              >
                <span class="d-inline mx-1 py-1 mt-3">Clear all</span>
              </b-link>
            </b-col>
            <b-col
             
              class="d-flex flex-row align-items-end justify-content-end mb-3 mb-md-4 px-1 py-1 align-self-end"
            >
              <b-button
                variant="main-green"
                class="d-flex flex-column align-items-start justify-content-center border-0 action-btn"
                pill
                @click="updateReport"
              >
                <span class="d-inline mx-1 py-1">Update Report</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-row>

      <!-- table container row -->

      <b-row
        class="d-flex flex-row align-items-stretch justify-content-center table-card-row w-100"
        no-gutters
      >
        <b-card
          no-body
          class="shadow-sm border-0 table-container-card px-3 py-3 w-100"
        >
          <!-- header row  -->

          <b-row
            class="d-flex flex-row align-items-center justify-content-start bg-main-green py-3 px-3 w-100"
            no-gutters
          >
            <!-- Accounts column -->

            <b-col
              class="d-flex flex-column align-items-start justify-content-center px-0 py-2 py-lg-0"
              cols="3"
            >
              <h6
                class="text-left text-uppercase font-weight-normal text-white"
              >
                Date
              </h6>
            </b-col>

            <!-- Accounts column -->

            <b-col
              class="d-flex flex-column align-items-start justify-content-center px-0 py-2 py-lg-0"
              cols="3"
            >
              <h6
                class="text-left text-uppercase font-weight-normal text-white"
              >
                Description
              </h6>
            </b-col>

            <!-- debit column -->

            <b-col
              class="d-flex flex-column align-items-end justify-content-center px-0 py-2 py-lg-0"
              cols="2"
            >
              <h6
                class="text-left text-uppercase font-weight-normal text-white"
              >
                Debit
              </h6>
            </b-col>

            <!-- credit column -->

            <b-col
              class="d-flex flex-column align-items-end justify-content-center px-0 py-2 py-lg-0"
              cols="2"
            >
              <h6
                class="text-left text-uppercase font-weight-normal text-white"
              >
                Credit
              </h6>
            </b-col>

            <!-- credit column -->

            <b-col
              class="d-flex flex-column align-items-end justify-content-center px-0 py-2 py-lg-0"
              cols="2"
            >
              <h6
                class="text-left text-uppercase font-weight-normal text-white"
              >
                Balance
              </h6>
            </b-col>
          </b-row>

          <!-- detail row  -->

          <h3 v-if="!Object.keys(generalLedgerData).length" class="text-center mt-5 text-muted">No records to show</h3>
          <b-overlay :show="isLoading" z-index="4" no-wrap></b-overlay>
          <b-row
            v-for="account in generalLedgerData"
            :key="account.title"
            class="d-flex flex-row align-items-center justify-content-start py-1 w-100"
            no-gutters
          >
            <b-row class="bg-light py-3 px-3 w-100" no-gutters>
              <!-- Accounts column -->

              <b-col
                class="d-flex flex-column align-items-start justify-content-center px-0 py-2 py-lg-0"
                cols="6"
              >
                <h6 class="text-left font-weight-bold text-dark">
                  {{ account.title }}
                </h6>

                <span class="text-left font-weight-normal text-gray-750">
                  {{ account.under }}
                </span>
              </b-col>
            </b-row>

            <b-row class="bg-light py-3 px-3 w-100" no-gutters>
              <!-- Accounts column -->

              <b-col
                class="d-flex flex-column align-items-start justify-content-center px-0 py-2 py-lg-0"
                cols="6"
              >
                <h6 class="text-left font-weight-bold text-dark">
                  Starting Balance
                </h6>
              </b-col>

              <b-col
                class="d-flex flex-column align-items-end justify-content-center px-0 py-2 py-lg-0"
                cols="6"
              >
                <h6 class="text-left font-weight-bold text-dark">
                  {{ currency }}
                  {{
                    account.startingBalance
                      ? getFormattedNumbers(account.startingBalance)
                      : "0.00"
                  }}
                </h6>
              </b-col>
            </b-row>

            <!-- detail row  -->

            <b-row
              class="py-1 my-2 px-3 w-100"
              no-gutters
              v-for="category in account.transactions"
              :key="category.id"
            >
              <!-- Accounts column -->

              <b-col
                class="d-flex flex-column align-items-start justify-content-center px-0 py-2 py-lg-0"
                cols="3"
              >
                <h6 class="text-left font-weight-normal text-dark">
                  {{ formatDateLegger(category.date)}}
                </h6>
              </b-col>

              <!-- Accounts column -->

              <b-col
                class="d-flex flex-column align-items-start justify-content-center px-0 py-2 py-lg-0"
                cols="3"
              >
                <h6 class="text-left font-weight-normal text-dark">
                  {{ category.description }}
                </h6>
              </b-col>

              <!-- Debit column -->

              <b-col
                class="d-flex flex-column align-items-end justify-content-center px-0 py-2 py-lg-0"
                cols="2"
              >
                <h6 class="text-left font-weight-normal text-dark">
                  {{ category.type == "income" ? currency : "" }}
                  {{
                    category.type == "income"
                      ? category.total_amount
                        ? getFormattedNumbers(category.total_amount)
                        : "0.00"
                      : ""
                  }}
                </h6>
              </b-col>

              <!-- Credit column -->

              <b-col
                class="d-flex flex-column align-items-end justify-content-center px-0 py-2 py-lg-0"
                cols="2"
              >
                <h6 class="text-left font-weight-normal text-dark">
                  {{ category.type == "expense" ? currency : "" }}
                  {{
                    category.type == "expense"
                      ? category.total_amount
                        ? getFormattedNumbers(category.total_amount)
                        : "0.00"
                      : ""
                  }}
                </h6>
              </b-col>

              <!-- Balance column -->

              <b-col
                class="d-flex flex-column align-items-end justify-content-center px-0 py-2 py-lg-0"
                cols="2"
              >
                <h6 class="text-left text-normal font-weight-normal text-dark">
                  {{ currency }}
                  {{
                    category.cummuliative
                      ? getFormattedNumbers(category.cummuliative)
                      : "0.00"
                  }}
                </h6>
              </b-col>
            </b-row>

            <!-- ending balance row  -->

            <b-row class="py-3 my-2 px-3 w-100" no-gutters>
              <!-- Accounts column -->

              <b-col
                class="d-flex flex-column align-items-start justify-content-center px-0 py-2 py-lg-0"
                cols="6"
              >
                <h6 class="text-left font-weight-bold text-dark">
                  Totals and Ending Balance
                </h6>
              </b-col>

              <!-- date column -->

              <b-col
                class="d-flex flex-column align-items-end justify-content-center px-0 py-2 py-lg-0"
                cols="2"
              >
                <h6 class="text-left text-normal font-weight-normal text-dark">
                  {{ currency }}
                  {{
                    account.ending.income
                      ? getFormattedNumbers(account.ending.income)
                      : "0.00"
                  }}
                </h6>
              </b-col>

              <!-- date column -->

              <b-col
                class="d-flex flex-column align-items-end justify-content-center px-0 py-2 py-lg-0"
                cols="2"
              >
                <h6 class="text-left text-normal font-weight-normal text-dark">
                  {{ currency }}
                  {{
                    account.ending.expense
                      ? getFormattedNumbers(account.ending.expense)
                      : "0.00"
                  }}
                </h6>
              </b-col>

              <!-- date column -->

              <b-col
                class="d-flex flex-column align-items-end justify-content-center px-0 py-2 py-lg-0"
                cols="2"
              >
                <h6 class="text-left text-normal font-weight-normal text-dark">
                  {{ currency }}
                  {{
                    account.ending.balance && account.startingBalance
                      ? getFormattedNumbers(
                          account.ending.balance + account.startingBalance
                        )
                      : "0.00"
                  }}
                </h6>
              </b-col>
            </b-row>

            <!-- total row  -->

            <b-row class="py-3 my-2 px-3 w-100" no-gutters>
              <!-- Accounts column -->

              <b-col
                class="d-flex flex-column align-items-start justify-content-center px-0 py-2 py-lg-0"
                cols="6"
              >
                <h6 class="text-left font-weight-bold text-dark">
                  Balance Change
                </h6>

                <span class="text-left font-weight-normal text-gray-750">
                  Difference between starting and ending balances
                </span>
              </b-col>

              <!-- date column -->

              <b-col
                class="d-flex flex-column align-items-end justify-content-center px-0 py-2 py-lg-0"
                cols="6"
              >
                <h6 class="text-left text-normal font-weight-normal text-dark">
                  {{ currency }}
                  {{
                    account.ending.balance
                      ? getFormattedNumbers(account.ending.balance)
                      : 0.0
                  }}
                </h6>
              </b-col>
            </b-row>
          </b-row>
        </b-card>
      </b-row>

      <!-- <b-overlay :show="isLoading" z-index="4" no-wrap></b-overlay> -->
    </b-container>
  </HomeLayout>
</template>

<script>
// @ is an alias to /src
import { baseURL } from "@/services/config";
import HomeLayout from "@/layout/HomeLayout";
import FormSelect from "@/components/Form/FormSelect";

//utils
import { formatNumbers } from "@/util/formatNumbers.js";

// services
import {
  GetGeneralLedger,
  GetGeneralFilters,
  ExportGeneralLedger,
  ExportPDFGeneralLedger,
} from "@/services/reports.service";
// services
import { GetAllCustomers } from "@/services/customers.service";
import { GetCashAndBank } from "@/services/transactions.service";
import {GetCurrentWorkspace} from "@/services/settingsbusiness.service";

export default {
  name: "GeneralLedger",
  components: {
    HomeLayout,
    FormSelect,
  },
  data() {
    return {
      isLoading: false,
      startDate: undefined,
      endDate: undefined,
      startDateError: null,
      endDateError: null,
      accountType: undefined,
      reportType: undefined,
      selectedCustomer: undefined,
      accountOptions: [],
      customersList: [],
      isFiltersApplied: false,
      dateRange: null,
      date_range:null,
      dateRangeOptions: ["This financial year","Last financial year","Last year Q1","Last year Q2","Last year Q3","Last year Q4"],
      reportTypeOptions: [
        {
          text: "Accrual (Paid & Unpaid)",
          value: "accrual",
        },
        {
          text: "Cash Basis (Paid)",
          value: "cash",
        },
      ],
      // reportTypeOptions: ["Accrual (Paid & Unpaid)", "Cash Basis (Paid)"],
      // generalLedgerData: {
      //   income: {
      //     title: "Uncategorized Income",
      //     under: "income > Uncategorized Income",
      //     startingBalance: 508664.6,
      //     ending: {
      //       income: 0,
      //       expense: 1674210.66,
      //       balance: 1674210.66,
      //     },
      //     transactions: [
      //       {
      //         type: "expense",
      //         total_amount: 3688.6,
      //         date: "2021-02-19T18:30:00.000Z",
      //         cummuliative: 512353.19999999995,
      //       },
      //       {
      //         type: "expense",
      //         total_amount: 1844.3,
      //         date: "2021-02-20T18:30:00.000Z",
      //         cummuliative: 514197.5,
      //       },
      //       {
      //         type: "expense",
      //         total_amount: 1500000,
      //         date: "2021-02-24T18:30:00.000Z",
      //         cummuliative: 2014197.5,
      //       },
      //       {
      //         type: "expense",
      //         total_amount: 1277.37,
      //         date: "2021-03-19T18:30:00.000Z",
      //         cummuliative: 2015474.87,
      //       },
      //       {
      //         type: "expense",
      //         total_amount: 638.69,
      //         date: "2021-03-20T18:30:00.000Z",
      //         cummuliative: 2016113.56,
      //       },
      //       {
      //         type: "expense",
      //         total_amount: 166761.7,
      //         date: "2021-03-28T18:30:00.000Z",
      //         cummuliative: 2182875.26,
      //       },
      //     ],
      //   },
      //   assets: {
      //     title: "Cash on Hand",
      //     under: "assets > Cash And Bank",
      //     startingBalance: 508664.6,
      //     ending: {
      //       income: 1674210.66,
      //       expense: 0,
      //       balance: 1674210.66,
      //     },
      //     transactions: [
      //       {
      //         type: "income",
      //         total_amount: 3688.6,
      //         date: "2021-02-19T18:30:00.000Z",
      //         description: "CR",
      //         cummuliative: 512353.19999999995,
      //       },
      //       {
      //         type: "income",
      //         total_amount: 1844.3,
      //         date: "2021-02-20T18:30:00.000Z",
      //         description: "CR",
      //         cummuliative: 514197.5,
      //       },
      //       {
      //         type: "income",
      //         total_amount: 1500000,
      //         date: "2021-02-24T18:30:00.000Z",
      //         description: "DR",
      //         cummuliative: 2014197.5,
      //       },
      //       {
      //         type: "income",
      //         total_amount: 1277.37,
      //         date: "2021-03-19T18:30:00.000Z",
      //         description: "CR",
      //         cummuliative: 2015474.87,
      //       },
      //       {
      //         type: "income",
      //         total_amount: 638.69,
      //         date: "2021-03-20T18:30:00.000Z",
      //         description: "CR",
      //         cummuliative: 2016113.56,
      //       },
      //       {
      //         type: "income",
      //         total_amount: 166761.7,
      //         date: "2021-03-28T18:30:00.000Z",
      //         description: "DR",
      //         cummuliative: 2182875.26,
      //       },
      //     ],
      //   },
      // },
      generalLedgerData: {},
      currency:localStorage.getItem('CURRENCY')
    };
  },

  watch: {
    selectedCustomer(val) {
      console.log("selectedCustomer", val);
    },
    startDate(val) {
    // reset the start date when end date changes
    this.endDate = '';
    if(val){
      this.date_range=null
     }
    },
    endDate(val){
     if(val){
      this.date_range=null
     }
    }
    
  },
  async mounted() {
    // await this.loadCurrentWorkspace();
    window.scrollTo(0, 0);
    await this.initFn();
    await this.getAccounts();

    const currentYear = new Date().getFullYear();
    // const startYear = currentYear - 20;
    // const endYear = currentYear + 20;
    // for (let i = startYear; i <= endYear; i++) {
    //   this.dateRangeOptions.push(i);
    // }
    this.dateRange = currentYear.toString();
  },
  computed: {

    minEndDate() {
      return this.startDate;
    },
    computedFilterValidations() {
      if (
        this.reportType ||
        this.startDate ||
        this.endDate ||
        this.accountType ||
        this.selectedCustomer ||
        this.date_range
      ) {
        return true;
      }
      if (this.isFiltersApplied) this.clearFilterFn();
      return false;
    },
  },
  methods: {
    async loadCurrentWorkspace() {
      let {data} = await GetCurrentWorkspace();
      this.currency = data.currency;
    },
    async initFn() {
      try {
        this.isLoading = true;
        let { data } = await GetGeneralLedger();
        this.generalLedgerData = data;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
      try {
        // get all customers list from API
        let { data } = await GetAllCustomers();
        this.customersList = data.results.results.map((x) => {
          return {
            text: x.name,
            value: x._id,
          };
        });
        console.log("customersList", this.customersList);
      } catch (error) {
        console.log(error);
      }
    },
    async updateReport() {
      if (
        this.startDate ||
        this.endDate ||
        this.reportType ||
        this.accountType ||
        this.selectedCustomer ||
        this.date_range
      ) {
        this.startDateError = true;
        this.endDateError = true;
        let payload = {
          start_date: this.startDate,
          end_date: this.endDate,
          report_type: this.reportType,
          account: this.accountType,
          customer: this.selectedCustomer,
          date_range:this.date_range,
        };
        console.log("update payload", payload);
        try {
          this.isLoading = true;
          let { data } = await GetGeneralFilters(payload);
          this.generalLedgerData = data;
          console.log("balance Sheet filter", data);
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          console.log(error);
        }
      } else {
        this.startDateError = false;
        this.endDateError = false;
      }
    },
    async exportGeneralLedgerFn() {
      let payload = {
        startDate: this.startDate,
        endDate: this.endDate,
        reportType: this.reportType,
        customer: this.selectedCustomer,
      };
      try {
        let { data, status } = await ExportGeneralLedger(payload);
        console.log("export ", data);
        if (status == 200) {
          console.log(baseURL);
          window.open(`${baseURL}/reports/download/${data}`);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async exportPDFGeneralLedgerFn() {
      let payload = {
        startDate: this.startDate,
        endDate: this.endDate,
        reportType: this.reportType,
        customer: this.selectedCustomer,
      };
      try {
        let { data, status } = await ExportPDFGeneralLedger(payload);
        console.log("export ", data);
        if (status == 200) {
          console.log(baseURL);
          window.open(`${baseURL}/reports/download/${data}`);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getAccounts() {
      try {
        let { data } = await GetCashAndBank();
        console.log("GetCashAndBank", data.data);
        this.accounts = data.data;
        console.log("this account", this.accounts);
        let accountsArray = this.accounts.map((account) => {
          return {
            value: {
              type: account.group,
              subcategory: account.subgroup,
              account: account.account,
            },
            text: account.account,
          };
        });
        this.accountOptions = [
          {
            label: this.accounts[0].subgroup,
            options: accountsArray,
          },
        ];
      } catch (error) {
        console.log(error);
      }
    },
    getFormattedNumbers(text) {
      if (!text) return "0.00";
      return formatNumbers(text);
    },
    formatDateLegger(fxDate){
      console.log("🚀 ~ file: GeneralLedger.vue:888 ~ formatDateLegger ~ fxDate:", fxDate)
      const dateObject = new Date(fxDate);
      const year = dateObject.getFullYear();
      let month = dateObject.getMonth() + 1; // Adding 1 because getMonth() returns a zero-based index
      let day = dateObject.getDate();
      if(day<10) day=`0${day}`
      if(month<10) month=`0${month}`
      const formattedDate = `${day}/${month}/${year}`;
      return formattedDate;
    },
    clearFilterFn() {
      this.initFn()
      this.startDate = undefined;
      this.endDate = undefined;
      this.reportType = undefined;
      this.accountType = undefined;
      this.selectedCustomer = undefined;
      this.isFiltersApplied = false;
      this.dateRange = new Date().getFullYear();
      this.date_range=null
    },
    formatDate(val) {
      if (val) {
        const dates = val.split("-");
        return `${dates[2]}/${dates[1]}/${dates[0]}`;
      }
    },
  },
};
</script>

<style scoped>
/* insights card */
.export-btn {
  width: 160px;
  height: 50px;
  border: 1.8px solid #0B9B7D;
}

.date-picker-btn >>> .btn .b-icon.bi {
  color: var(--main-green);
}

.date-picker-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: 1px solid var(--main-green);
  height: 38px;
}

.filter-cards-row {
  padding: 25px;
  height: 240px;
  width: 100%;
  background-color: var(--white);
  border-radius: 5px;
  border: 1px solid var(--main-green);
  margin-bottom: 30px;
}

.action-btn:hover {
  background: #0B9B7D;
  box-shadow: inset 0px 0px 10px #ffffff;
  border: none;
}

.filter-cards-row .date-filter-row {
  width: 100%;
}

/* table card */

.table-card-row {
  height: 100%;
}

.table-card-row .table-container-card {
  background-color: var(--white);
  width: 100%;
  min-height: 560px;
  border-radius: 5px;
  border: 1px solid var(--light);
}

.table-card-row .table-container-card >>> .tab-wrapper {
  /* border-bottom: 0.5px solid var(--gray-750); */
  border-bottom: none;
}

.table-card-row .table-container-card >>> .invoice-tabs {
  width: 100%;
  font-size: 1rem;
  overflow: hidden;
  /* background-color: var(--ghost-white); */
}

.table-card-row .table-container-card >>> .invoice-tabs .tab-nav-link {
  font-size: 0.9rem;
  padding-right: 20px;
  white-space: nowrap;
  background: transparent;
  border: transparent;
  color: var(--gray-750);
  /* border-bottom: 1px solid var(--prime-gray); */
}

.table-card-row .table-container-card >>> .invoice-tabs .tab-nav-link:hover {
  background-color: var(--light);
  color: var(--main-green);
}

.table-card-row .table-container-card >>> .invoice-tabs .tab-nav-link.active {
  background-color: var(--light);
  border-bottom: 2px solid var(--main-green);
}

/* Medium devices (tablets, less than 992px) */

@media (max-width: 991.98px) {
  .filter-cards-row {
    min-height: 420px;
    padding: 20px 5px;
    margin: 20px 5px;
    width: 100%;
  }

  .filter-cards-row .date-filter-row {
    width: 100%;
  }
}

/* Small devices (landscape phones, less than 768px) */

@media (max-width: 767.98px) {
  .filter-cards-row {
    min-height: 480px;
    padding: 20px 0px;
    margin: 5px;
    width: 100%;
  }

  .filter-cards-row .date-filter-row {
    width: 100%;
  }
}
</style>
