<template>
    <HomeLayout>
      <b-container
        class="d-flex flex-column align-items-start justify-content-start px-0"
      >
        <!-- table container row -->
        <b-row
          class="d-flex flex-row align-items-stretch justify-content-center table-card-row w-100"
          no-gutters
        >
          <b-card no-body class="shadow-sm border-0 table-container-card w-100">
            <!-- table actions row -->
            <b-row
              class="d-flex flex-row align-items-center justify-content-between w-100 my-1 my-md-2 px-lg-3 py-3"
              no-gutters
            >
              <!-- search input column -->
              <b-col
                class="d-flex flex-column align-items-start justify-content-center px-4 px-lg-0 py-2 py-lg-0"
                cols="12"
                md="4"
              >
                <!-- table search input -->
                <b-input-group class="search-input-group">
                  <b-form-input
                    class="search-input border-0 bg-light"
                    type="text"
                    placeholder="Search by name or number"
                    v-model="searchTable"
                    @keyup.native.enter="searchFn"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-row
                      class="d-flex flex-row align-items-center justify-content-center px-3 border-0 rounded-right bg-light"
                      no-gutters
                    >
                      <template v-if="searchTable">
                        <b-icon
                          class="text-main-green mr-2"
                          icon="x"
                          size="lg"
                          aria-label="Search"
                          @click="close"
                        ></b-icon>
                      </template>
                      <b-icon
                        class="text-main-green"
                        icon="search"
                        size="lg"
                        aria-label="Search"
                      ></b-icon>
                    </b-row>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <b-col
              class="d-flex flex-row align-items-end justify-content-end mr-2 py-2 py-lg-0"
                cols="12"
                md="5"
              >
              <b-dropdown
                    id="dropdown-form"
                    ref="dropdown"
                    variant="light"
                    class="mx-2 my-2 my-md-0 border-0 filter-dropdown "
                    toggle-class="d-flex flex-row align-items-center justify-content-center add-question-btn  my-2 my-lg-0 px-4 py-3 border text-main-green border-main-green rounded-pill"
                    menu-class="w-100 bg-white border border-main-green py-1 filter-dropdown-menu px-2"
                    right
                    no-caret
                    no-flip
                    @show="filterDropDownShow = true"
                    @hide="filterDropDownShow = false"
                >
                  <template v-slot:button-content>
                    <span class="d-inline mx-2">Export</span>
                  </template>
                  <b-dropdown-group flush class="rounded-lg py-2">
                    <b-dropdown-item-button @click="exportPDFFixedAssertFn"
                        class="py-1" 
                       
                    >Export as PDF
                    </b-dropdown-item-button
                    >
                  
                    <b-dropdown-item-button
                        class="py-1"
                        @click="exportFixedAssertCsv"
                      
                    >Export as CSV
                    </b-dropdown-item-button
                    >
                   
                    <b-dropdown-item-button class="py-1"  @click.native="exportFixedAssertXlsx"
                    >Export as Excel
                    </b-dropdown-item-button
                    >
                    
                  </b-dropdown-group>
                </b-dropdown>
                <b-button
                  variant="main-green"
                  class="d-flex flex-row align-items-center justify-content-center mx-4 mx-md-0 my-2 my-lg-0 px-4 py-2 rounded-lg add-question-btn border-0"
                  @click="openCreateAsset( null,$event.target)"
                  pill
                >
                  <span class="d-inline ml-2">Create an asset</span>
                </b-button>
              </b-col>
            </b-row>
            <!-- table row -->
            <b-row
              class="d-flex flex-row align-items-start justify-content-start px-3 w-100 table-container-row"
              no-gutters
            >
              <b-table
                ref="questionsTable"
                :bordered="false"
                :borderless="true"
                :hover="true"
                selectable
                show-empty
                select-mode="single"
                class="table-element"
                thead-class="table-header font-secondary"
                tbody-class="table-body"
                :busy="isLoading"
                :per-page="perPage"
                :fields="customersHeader"
                :items="fixedAssestItems"
                @row-clicked="onTableRowClicked"
              >
                <!-- table busy state -->
                <template #table-busy>
                  <div class="text-center text-muted my-3">
                    <b-spinner class="align-middle"></b-spinner>
                    <span class="text-left mx-1">Loading...</span>
                  </div>
                </template>
                <!-- table empty state -->
                <template #empty="scope">
                  <b-row
                    class="d-flex flex-row align-items-center justfiy-content-center py-5"
                    no-gutters
                  >
                    <h6 class="text-center mb-0 mx-auto my-5">
                      {{ scope.emptyText }}
                    </h6>
                  </b-row>
                </template>
                <!-- Asset Name  -->
                <template #cell(AssetName)="data">
                  <b-col
                    class="d-flex flex-column align-items-start justify-content-center px-0"
                  >
                    <span class="main-text">{{ data.value}}</span>
                    
                  </b-col>
                </template>
                <!-- Asset Number -->
                <template #cell(AssetNumber)="data">
                  <span class="main-text mb-2">{{ data.value }}</span>
                </template>
                <!-- Asset Type  -->
                <template #cell(AssetType)="data">
                  <span class="main-text mb-2">{{ data.value }}</span>
                </template>
                <!-- Asset Date  -->
                <template #cell(AssetPurchaseDate)="data">
                  
                    <span class="main-text mb-2"
                      >{{ formatDate( data.value)}}</span
                    >
                    
                  
                </template>
                <!-- Asset Price  -->      
                <template #cell(AssetPrice)="data">
                  <span class="main-text mb-2">{{ getFormatedAmount(data.value) }}</span>
                </template>
                <!-- Asset BookValue  -->
                <template #cell(BookValue)="data">
                  <span class="main-text mb-2">{{ data.value }}</span>
                </template>
                <!-- actions column -->
                <template #cell(actions)="data">
            <b-col >
            <b-button
             
              title="Edit Item"
              class="shadow-none   edit-btn"
              @click="editCustomerFn(data.item,$event.target)"
              
            >
              <b-icon
                icon="pencil-square"
                aria-label="Edit Item"
                class="edit-icon"
                
              ></b-icon>
            </b-button>
         
            <b-button
             
              title="Remove Item"
              class="shadow-none  delete-btn"
              @click="openDeleteCustomerModal(data.item, $event.target)"
             
            >
              <b-icon
                icon="trash"
                aria-label="Remove Item"
                class="delete-icon"
              ></b-icon>
            </b-button>
          </b-col>
                </template>
              </b-table>
            </b-row>
            <!-- table controls -->
            <!-- safari fix (flex-shrink-0) -->
            <b-row
              class="d-flex flex-row align-items-center justify-content-center justify-content-md-end flex-shrink-0 px-3 py-2 w-100"
              no-gutters
            >
              <!-- pages per page control -->
              <b-col
                class="d-flex flex-column align-items-center align-items-sm-start align-items-md-end align-items-xl-start justify-content-center py-2 py-md-1"
                cols="12"
                sm="6"
                lg="5"
                xl="2"
              >
                <b-form-group
                  label="Per page"
                  label-cols="12"
                  label-cols-sm="6"
                  label-align="left"
                  label-align-sm="right"
                  label-size="sm"
                  label-for="perPageSelect"
                  class="mb-0 w-100"
                >
                  <b-form-select
                    v-model="perPage"
                    id="perPageSelect"
                    size="sm"
                    class="per-page-select border-1 border-main-green py-0"
                    :options="pageOptions"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <!-- pagination controls -->
              <b-col
                class="d-flex flex-column align-items-center align-items-sm-end justify-content-center py-2 py-md-1"
                cols="12"
                sm="6"
                lg="4"
                xl="5"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="right"
                  size="md"
                  limit="4"
                  pills
                  class="table-pagination"
                  page-class="custom-page-item"
                  ellipsis-class="custom-page-item"
                  first-class="custom-page-item"
                  last-class="custom-page-item"
                  next-class="custom-page-item"
                  prev-class="custom-page-item"
                >
                  <template #first-text>
                    <b-icon
                      class="text-main-green"
                      icon="chevron-left"
                      size="sm"
                      aria-label="Plus"
                    ></b-icon>
                  </template>
                  <template #prev-text>
                    <b-icon
                      class="text-main-green"
                      icon="chevron-double-left"
                      size="sm"
                      aria-label="Plus"
                    ></b-icon>
                  </template>
                  <template #next-text>
                    <b-icon
                      class="text-main-green"
                      icon="chevron-double-right"
                      size="sm"
                      aria-label="Plus"
                    ></b-icon>
                  </template>
                  <template #last-text>
                    <b-icon
                      class="text-main-green"
                      icon="chevron-right"
                      size="sm"
                      aria-label="Plus"
                    ></b-icon>
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </b-card>
          <DeleteCustomerModal
            :isShow="isShowDeleteItemRow"
            @onClose="onCloseDeleteCustomerModal"
            :key="showDeleteCustomerModalKey"
            :itemContent="rowItem"
            @reloadFn="callInitFn"
          />
        </b-row>
        <CreateAssetModal
        :itemContent="rowItem"
        :isEdit = "this.IsEdit"
        @reloadFn="callInitFn"
        />
        <AssetDetailsModal
        :assetId="this.selectedAssetId"
        ref="assetDetailsModal"
        @reloadFn="callInitFn"
        />
      </b-container>
    </HomeLayout>
  </template>
  
  <script>
  // @ is an alias to /src
  import HomeLayout from "@/layout/HomeLayout";
  import DeleteCustomerModal from "@/components/Customers/modals/DeleteCustomerModal";
  import CreateAssetModal from "@/components/Transactions/Modals/CreateAssetModal";
  import AssetDetailsModal from "@/components/Transactions/Modals/AssetDetailsModal";
  import { formatNumbers } from "@/util/formatNumbers.js";
  import { GetAllAssets,SearchAssets ,ExportPDFFixedAssert,ExportCSVFixedAssert} from "@/services/fixedassets.service";
  import {GetCurrentWorkspace} from "@/services/settingsbusiness.service";
  import { baseURL } from "@/services/config";
  // services
  
  
  export default {
    name: "FixedAssests",
    components: {
      HomeLayout,
      DeleteCustomerModal,
      CreateAssetModal,
      AssetDetailsModal
      
    },
    data() {
      return {
        isShowDeleteItemRow: false,
        showDeleteCustomerModalKey: Number(new Date()) + 100,
        rowItem: null,
        rowIndex: null,
        AssetID: null,
        filterDropDownShow: false,
        selectedRegion: {
          title: null,
          code: null,
          flagCode: null,
          languageCode: null,
        },
        isRegionFilter: false,
        searchTable: null,
        isLoading: false,
        selectedAssetId: null,
        IsEdit: false,
        customersHeader: [
          {
            key: "AssetName",
            label: "Asset Name",
            sortable: true,
          },
          {
            key: "AssetNumber",
            label: "Number",
            sortable: true,
          },
          {
            key: "AssetType",
            label: "Type",
            sortable: true,
          },
          {
            key: "AssetPurchaseDate",
            label: "Purchased Date",
            class: "text-center",
            sortable: true,
          },
          {
            key: "AssetPrice",
            label: "Price(NZD)",
            sortable: true,
          },
          {
            key: "BookValue",
            label: "Book Value",
            sortable: true,
          },
          {
            key: "actions",
            label: "Actions",
            class: "text-center",
          },
        ],
        fixedAssestItems: [
          // {
          //   AssetName: "Test Equipment",
          //   AssetNumber:"12345",
          //   AssetType: "Test",
          //   AssetPurchaseDate: "2023/07/02",
          //   AssetPrice: "120",
          //   BookValue:"Test"
          // },
          
        ],
        // table pagination controls
        currentPage: 1,
        perPage: 5,
        pageOptions: [5, 10, 15, 20],
        totalRows: 0,
        currency:localStorage.getItem('CURRENCY'),
      };
    },
    
    async mounted() {
      await this.initFn();
      // await this.loadCurrency();
      
    },
    computed: {
   
    isAssest() {
      return window.location.href.toString().includes("assets");
    },
  },
    watch: {
      searchTable() {
        this.searchFn();
      },
      perPage() {
        this.initFn();
      },
      currentPage() {
        this.initFn();
      },

    },
    methods: {
      openCreateAsset(isEdit,button) {
     this.IsEdit = isEdit;
     this.rowItem = null;
     console.log("clicked",this.IsEdit);
     this.$root.$emit("bv::show::modal", `create-asset-modal`,button);
    
     
   },

  async exportPDFFixedAssertFn(){
    let { data, status } = await ExportPDFFixedAssert();
        console.log("export ", data);
        if (status == 200) {
          console.log(baseURL);
          window.open(`${baseURL}/reports/download/${data}`);
        }
   },

   async exportFixedAssertCsv(){
    const payload ={
      type:'csv'
    }
    let { data, status } = await ExportCSVFixedAssert(payload);
        console.log("export ", data);
        if (status == 200) {
          console.log(baseURL);
          window.open(`${baseURL}/reports/download/${data}`);
        }
   },

   async exportFixedAssertXlsx(){
    console.log('AAAAAAAAAAAAAAAAAAAa')
    const payload ={
      type:'xlsx'
    }
    let { data, status } = await ExportCSVFixedAssert(payload);
        console.log("export ", data);
        if (status == 200) {
          console.log(baseURL);
          window.open(`${baseURL}/reports/download/${data}`);
        }
   },

   async loadCurrency() {
      let {data} = await GetCurrentWorkspace();
      this.currency = data.currency;
    },
    async initFn(val = undefined) {
      try {
        if (val) this.currentPage = val;
        this.isLoading = true;
        let { data } = await GetAllAssets(
          {
            limit: this.perPage,
          page: this.currentPage,
          }
        );
        console.log("Assest Data", data)
        this.totalRows = data.results.total;
       
        this.fixedAssestItems = data.results.results.map((x) => {
          return {
            AssetID: x._id,
            AssetName: x.name,
            AssetNumber:x.number,
            AssetType: x.type,
            AssetPurchaseDate: x.purchased_date,
            AssetPrice:x.purchased_price,
            BookValue: x.book_value,
          
            
          };
        });
        console.log(" AssetName", )

        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },

     
      async searchCustomers() {
        try {
          this.fixedAssestItems = [];
          this.isLoading = true;
          let { data } = await SearchAssets({ search : this.searchTable });
          console.log("customer",data);
          this.fixedAssestItems = data.data.map((x) => {
            return {
            AssetID: x._id,
            AssetName: x.name,
            AssetNumber:x.number,
            AssetType: x.type,
            AssetPurchaseDate: x.purchased_date,
            AssetPrice:x.purchased_price,
            BookValue: x.book_value,
              actions: {
                id: x._id,
              },
            };
          });
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          console.log(error);
        }
      },
      searchFn() {
        // this.searchCustomers(1);
        if (this.searchTable && this.searchTable.length > 0) {
          // call the init function to load the data based on the search query
          // pass 1 into the function to reset the current page to 1, before executing the search to ensure the search doesn't get hindered by the pagination
          this.searchCustomers();
        }else{
          this.initFn();
        }
      },
      openDeleteCustomerModal(item, button) {
        // this.$store.dispatch("setSelectedItem", item);
        this.isShowDeleteItemRow = true;
        this.rowItem = item;
        console.log("Item",item)
        this.$root.$emit("bv::show::modal", "delete-customer-row-modal", button);
      },
      onCloseDeleteCustomerModal() {
        this.isShowDeleteItemRow = false;
      },
      callInitFn() {
        this.showDeleteCustomerModalKey = Number(new Date()) + 100;
        this.initFn();
      },
     
      editCustomerFn(item, button) {
       
        this.rowItem = item;
        this.IsEdit = true;
        console.log("Item,edit",this.IsEdit)
      
  
       this.$root.$emit("bv::show::modal", `create-asset-modal`,button);
      },
      onTableRowClicked(row) {
        this.initFn()
        this.selectedAssetId = row.AssetID;
        console.log("row",this.selectedAssetId)
       
         this.$root.$emit("bv::show::modal", `asset-deatls-modal`);
        
      },
      getFormatedAmount(value) {
        // if value is null/undefined/0 return dash '-'
        if (!value) return "N/A";
        // check if the value is anumber/integer or else parse it accordingly
        let num = isNaN(value) ? parseInt(value) : value;
        // if the number is a whole number return as is, if it is a decimal number then return the number in 2 decimal places
        let parsedNum =
          num % 1 != 0 ? (Math.round(num * 100) / 100).toFixed(2) : num;
        // format and then return the number
        return this.getFormattedNumbers(parsedNum);
      },
      getFormattedNumbers(text) {
        if (!text) return "N/A";
        return formatNumbers(text);
      },
      formatDate(val) {
        if (val) {
          const dateObj = new Date(val);
      const year = dateObj.getFullYear();
      const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
      const day = dateObj.getDate().toString().padStart(2, "0");
      return `${day}/${month}/${year}`;
      }
    },
      close() {
        this.searchTable = null;
        this.initFn();
      },
    },
  };
  </script>
  
  <style scoped>
  /* table card */
  .table-card-row {
    height: 100%;
  }
  
  .table-card-row .table-container-card {
    background-color: var(--white);
    width: 100%;
    min-height: 560px;
    border-radius: 0;
    border: 1px solid var(--light);
  }
  
  /* table actions row */
  .table-card-row .search-input-group {
    box-shadow: none;
    height: 50px;
    min-width: 200px;
  }
  
  .table-card-row .search-input-group .search-input {
    box-shadow: none;
    height: 50px;
    /* min-width: 180px; */
  }
  
  .table-card-row .search-input-group .search-input::placeholder {
    color: #0B9B7D;
  }
  
  .table-card-row .filter-dropdown,
  .table-card-row .add-question-btn {
    height: 45px;
    width: 180px;
  }
  
  .table-card-row .add-question-btn:hover {
    background: #0B9B7D;
    box-shadow: inset 0px 0px 10px #ffffff;
  }
  
  .table-card-row .filter-dropdown >>> .filter-dropdown-menu {
    height: auto;
    min-width: 200px;
    max-height: 50vh;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: thin;
  }
  
  .table-card-row
    .filter-dropdown
    >>> .filter-dropdown-menu
    button.dropdown-item.active,
  .table-card-row
    .filter-dropdown
    >>> .filter-dropdown-menu
    button.dropdown-item:active {
    background-color: var(--light);
  }
  
  /* table container */
  .table-container-row {
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: thin;
  }
  
  /* scrollbar size fix for webkit browsers (chrome/safari) */
  .table-container-row::-webkit-scrollbar {
    height: 0.5rem;
    scrollbar-width: thin;
  }
  
  .table-container-row::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f0f0f0;
  }
  
  .table-container-row::-webkit-scrollbar-thumb {
    background-color: #cdcdcd;
  }
  
  .table-container-row .table-element {
    min-width: 1000px;
  }
  
  /* table element css */
  .table-element >>> .table-header {
    /* background-color: #f7f7ff; */
    background-color: transparent;
  }
  
  .table-element >>> .table-header th {
    color: var(--prime-gray);
    font-size: 0.8rem;
    font-weight: 400;
    border-color: transparent;
  }
  
  .table-element >>> .table-body td {
    vertical-align: middle;
  }
  
  .table-element >>> .table-body tr:nth-child(even) {
    vertical-align: middle;
    background-color: var(--light);
  }
  
  .table-element >>> .table-body .main-text {
    font-size: 0.9rem;
  }
  
  .table-element >>> .table-body .sub-text {
    font-size: 0.7rem;
  }
  
  /* question row */
  .table-element >>> .table-body .question-row {
    max-width: 550px;
  }
  
  /* actions row */
  .table-element >>> .table-body .actions-row {
    min-width: 140px;
  }
  
  /* more icon button (default state) */
  .table-element >>> .table-body .more-btn {
    width: 35px;
    height: 35px;
    padding: 7px;
    background-color: #0B9B7D;
    transition: background-color 200ms ease-in;
  }
  
  .table-element >>> .table-body .more-btn > .more-icon {
    color: var(--white);
    transition: color 200ms ease-in;
  }
  
  /* more icon button (hover state) */
  .table-element >>> .table-body .more-btn:hover {
    background-color: var(--prime-gray);
  }
  
  .table-element >>> .table-body .more-btn:hover > .more-icon {
    color: var(--white);
  }
  
  /* table question action buttons */
  /* edit icon button (default state) */
  .table-element >>> .table-body .edit-btn {
    width: 40px;
    height: 40px;
    padding: 7px;
    background-color:transparent;
    border: none;
  
  }
  
  .table-element >>> .table-body .edit-btn > .edit-icon {
    color: #0B9B7D;
    
  }
  
  /* edit icon button (hover state) */
 
  
  /* delete icon button (default state) */
  .table-element >>> .table-body .delete-btn {
    width: 40px;
    height: 40px;
     border: none;
    background-color:transparent;
   
  }
  .table-element >>> .table-body .delete-btn > .delete-icon {
    color: var(--danger);
   
  }
  
  /* delete icon button (hover state) */
  
  
  
  
  /* expand icon button (default state) */
  .table-element >>> .table-body .expand-btn {
    width: 40px;
    height: 40px;
    padding: 7px;
    background-color: var(--white);
    transition: background-color 200ms ease-in;
  }
  
  .table-element >>> .table-body .expand-btn > .expand-icon {
    color: var(--prime-gray);
    transition: color 200ms ease-in;
  }
  
  /* expand icon button (hover state) */
  .table-element >>> .table-body .expand-btn:hover {
    background-color: var(--prime-gray);
  }
  
  .table-element >>> .table-body .expand-btn:hover > .expand-icon {
    color: var(--white);
  }
  
  .table-element >>> .table-body .b-table-details {
    background-color: #fff;
  }
  
  .table-element >>> .table-body .b-table-details:hover {
    cursor: auto;
    background-color: #fff;
  }
  
  /* table per page selector */
  .per-page-select {
    cursor: pointer;
    min-width: 65px;
  }
  
  /* table pagination */
  .table-pagination >>> .custom-page-item button,
  .table-pagination >>> .custom-page-item span {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    border-color: transparent;
    border-radius: 0;
    color: var(--dark);
    padding: 0.5rem 0.6rem;
    font-size: 18px;
    height: 20px;
    transition: all 150ms ease-in;
  }
  
  .table-pagination >>> .custom-page-item button:hover {
    color: #0B9B7D;
    /* border-color: var(--secondary); */
    background-color: var(--light);
  }
  
  .table-pagination >>> .custom-page-item.active button,
  .table-pagination >>> .custom-page-item.active span {
    color: #0B9B7D;
    border: none;
    border-color: transparent;
    font-weight: bold;
    background-color: transparent;
  }
  
  .table-pagination >>> .custom-page-item.disabled button,
  .table-pagination >>> .custom-page-item.disabled span {
    opacity: 0.5;
    border-color: transparent;
    background-color: transparent;
  }
  
  /* Small devices (landscape phones, less than 768px) */
  /* @media (max-width: 767.98px) {
    .table-card-row .search-input-group {
      max-width: none;
    }
  } */
  </style>